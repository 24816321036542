
const lookups = {
  widgetDateRangeOptions: [
    { label: 'Last 24 hours', code: 'DATE-1D' },
    { label: 'Last 2 days', code: 'DATE-2D' },
    { label: 'Last 7 days', code: 'DATE-7D' },
    { label: 'Last 14 days', code: 'DATE-14D' },
    { label: 'Last month', code: 'DATE-1M' },
    { label: 'Last 2 months', code: 'DATE-2M' },
    { label: 'Last 3 months', code: 'DATE-3M' }
  ],
  widgetWidthOptions: [
    { label: '1/2 of screen', code: 'lg:w-1/2' },
    { label: '1/4 of screen', code: 'lg:w-1/4' },
    { label: '1/3 of screen', code: 'lg:w-1/3' },
    { label: '2/3 of screen', code: 'lg:w-2/3' },
    { label: 'Full width', code: 'lg:w-full' }
  ],
  latLonAccuracyOptions: [
    { label: 'Low', code: 1 },
    { label: 'Standard', code: 2 },
    { label: 'High', code: 3 }
  ],
  searchTypeOptions: [
    { label: 'Accomodation', code: 1 },
    { label: 'Ticket/Attraction', code: 2 },
    { label: 'Car Rental', code: 3 },
    { label: 'Taxi/Transfer', code: 4 },
    { label: 'Flights', code: 5}
  ],
  phoneTypeOptions: [
    { label: 'Generic', code: 1 },
    { label: 'Mobile', code: 2 },
    { label: 'Home', code: 3 },
    { label: 'Office', code: 4 },
  ],
  titleOptions: [
    { label: 'Unspecified', code: 0 },
    { label: 'Mr.', code: 1 },
    { label: 'Miss.', code: 2 },
    { label: 'Mrs.', code: 3 },
    { label: 'Ms.', code: 4 },
    { label: 'Master', code: 5 },
    { label: 'Dr.', code: 6 },
  ],
  languageOptions: [
    { label: 'English',  code: 'english'  },
    { label: 'Spanish',  code: 'spanish'  },
    { label: 'French',   code: 'french'   },
    { label: 'Russian',  code: 'russian'  },
    { label: 'German',   code: 'german'   },
    { label: 'Arabic',   code: 'arabic'   },
    { label: 'Sanskrit', code: 'sanskrit' }
  ],
  countryOptions: [
    { label: 'Canada', code: 'CA' },
    { label: 'France', code: 'FR' },
    { label: 'Germany', code: 'DE' },
    { label: 'Ireland', code: 'IE' },
    { label: 'Malta', code: 'MT' },
    { label: 'Portugal', code: 'PT' },
    { label: 'Spain', code: 'ES' },
    { label: 'Uganda', code: 'UG' },
    { label: 'United Kingdom', code: 'GB' },
    { label: 'United States', code: 'US' }
  ],
  companyTypeOptions: [
    { label: 'Demo', code: 'Demo' },
    { label: 'Travel Agent', code: 'TravelAgent' },
    { label: 'Tour Operator', code: 'TourOp' },
    { label: 'Travel Reseller', code: 'Reseller' },
    { label: 'Loyalty Program', code: 'Loyalty' },
    { label: 'Content Company', code: 'Content' },
    { label: 'Social Company', code: 'Social' },
    { label: 'Other', code: 'Other' }
  ],
  applicationCurrencies: [
    { label: 'Australian Dollar',  code: 'AUD'  },
    { label: 'Canadian Dollar',  code: 'CAD'  },
    { label: 'Euros',   code: 'EUR'   },
    { label: 'British Pound',  code: 'GBP'  },
    { label: 'US Dollars',   code: 'USD'   }
  ],
  applicationLanguageOptions: [
    { label: 'English',  code: 'en'  },
    { label: 'Spanish',  code: 'es'  },
    { label: 'French',   code: 'fr'   },
    { label: 'German',  code: 'de'  }
  ],
  applicationNationalities: [
    { label: 'GB',  code: 'GB'  },
    { label: 'US',  code: 'US'  },
    { label: 'CA',   code: 'CA'   }
  ],
}

export default lookups;
