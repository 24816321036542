<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col w-full" >
            <vs-button v-if="userHasPermission('company_roles_edit')" @click="addRole" class="mr-4 sm:mb-0 mb-2">Add</vs-button>
            <vs-input class="float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
          </div>
        </div>
        
        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="rolesData"
          :gridOptions="gridOptions"
          rowSelection="single"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @selection-changed="onSelectionChanged"
        >
        </ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="5"
          v-model="currentPage" />
      </vx-card>

      <vs-prompt
        title="Delete Role"
        accept-text="Add"
        @cancel="closeDeleteRoleDialog"
        @close="closeDeleteRoleDialog"
        @accept="deleteRole"
        :active.sync="showDeleteRolePopup">

          <div class="vx-row mt-2 mb-4">
            <div class="vx-col w-full">
              <label class="text-sm">Assign Replacement Role to Users:</label>
              <v-select v-model="selectedReplacementRole" :options="replacementRoleOptions" />
            </div>
          </div>
      </vs-prompt>
    </div>
  </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererLink from './cell-renderers/CellRendererLink.vue'
import CellRendererRoleType from './cell-renderers/CellRendererRoleType.vue'
import CellRendererActions from './cell-renderers/CellRendererActions.vue'
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'
import roleStore from './roleStore.js'
import Vue from "vue"
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';

export default {
  components: {
    AgGridVue,
    CellRendererLink,
    CellRendererRoleType,
    CellRendererActions,
  },
  mixins: [TpNotificationMixin],
  data () {
    return {
      dummyData: '',
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererLink,
        CellRendererRoleType,
        CellRendererActions
      },
      searchQuery: '',
      showDeleteRolePopup: false,
      selectedReplacementRole: {}
    }
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    rolesData () {
      return this.$store.state.roleStore.companyRoles
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
    replacementRoleOptions () {
      return this.$_.map(this.$store.state.roleStore.companyRoles, x => {
        return { code: x.roleId, label: x.roleName }
      })
    }
  },
  created () {
    if (!roleStore.isRegistered) {
      this.$store.registerModule('roleStore', roleStore)
      roleStore.isRegistered = true
    }

    //Check if user has acccess to the company
    if ( this.activeUserInfo.companyId != this.$route.params.companyId ) {
      this.$router.push('/error-404')
    }

    this.$store.dispatch('roleStore/fetchRoles', this.$route.params.companyId )
      .catch(err => console.error(err) )
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'roleId', hide: true },
      { field: 'companyId', hide: true },
      { headerName: 'Actions', width: 150, sortable: false, suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererActions) },
      { headerName: 'Role', field: 'roleName', sortable: true, suppressSizeToFit: true, width: 250, cellRendererFramework: Vue.extend(CellRendererLink) },
      // Supppress size to fit excludes column from auto resize if gridApi.sizeColumnsToFit() is called
      { headerName: 'Description', field: 'roleDescription' },
      { headerName: 'Type', field: 'roleType', width: 150, suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererRoleType) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  
  methods: {
    addRole () {
      this.$router.push({ name: 'company-roles-addition', params: { companyId: this.activeUserInfo.companyId}})
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows();
    },
    onGridReady(params) {

    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    deleteRole () {

    },
    closeDeleteRoleDialog () {

    }
  }
}
</script>

<style>

</style>