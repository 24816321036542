<template>
<div class="flex flex-wrap items-center">
  <!-- <vs-avatar 
    :src="this.params.data.logoUrl"
    :text="this.params.data.applicationName" 
    class="application-logo-avatar flex-shrink-0 mr-2" 
    size="40px"
    @click="$router.push(this.params.data.logUrl)" /> -->
  <span>{{this.params.data.applicationName}}</span>
  <vs-chip v-if="this.params.data.isDemo" class="ag-grid-cell-chip mt-5 ml-2" color="danger"><span>Demo</span></vs-chip>
</div>
  <!-- <div class="flex items-center"> -->
    <!-- <vs-avatar :src="params.data.avatar" class="flex-shrink-0 mr-2" size="30px" @click="$router.push(url)" /> -->
  
  <!-- </div> -->
    
    
</template>

<script>
export default {
  name: 'CellRendererAppName',
  computed: {
    chipColor () {
      return (value) => {
        if (value && value.toLowerCase() === 'custom') return 'success'
        else if (value && value.toLowerCase() === 'system') return 'danger'
        else return 'primary'
      }
    }
  }
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger),.15);
    color: rgba(var(--vs-danger),1) !important;
    font-weight: 500;
  }
}

.application-logo-avatar {
	.con-vs-avatar {
		border-radius: 10%;
	}
	.vs-avatar--con-img {
		border-radius: 10%;
	}
}
</style>
