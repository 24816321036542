<template>
  <div class="flex items-center w-full cell-renderer-select-container">
      <!-- append-to-body very important https://vue-select.org/api/props.html#appendtobody -->
      <v-select :value="selectedRole" append-to-body  @input="setSelectedRole" :options="roleOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  name: 'CellRendererSelectRole',
  components: {
    vSelect
  },
  data () {
    return {
      selectedRole: {},
    }
  },
  computed: {
    roleOptions () {
      return this.$store.state.userStore.companyRoleOptions
    }
  },
  mounted () {
    this.selectedRole = this.$_.find(this.roleOptions, x =>
      x.code === this.params.data.roleId) || {}
  },
  methods: {
    setSelectedRole (value) {
      // this.roleId = value ? value.code : null
      this.params.data.roleId = value ? value.code : null;
      this.selectedRole = value;
    },
  }
}
</script>

<style>
/* Make sure the checkbox centers vertically in the row*/
.cell-renderer-select-container {
  height: 100%;
}
/* Make drop-down options visible and fill up the entire cell */
.cell-renderer-select-container .v-select {
  width: 100% !important;
}

/* Succeding styles fixes wrapping for long text- https://github.com/sagalbot/vue-select/issues/754 */
.cell-renderer-select-container .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 41px);
}

.cell-renderer-select-container .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

.cell-renderer-select-container .vs__search {
  position: absolute;
  height: 100%;
}

.cell-renderer-select-container .vs--open .vs__search {
  position: static;
}

.cell-renderer-select-container .vs__dropdown-option {
  white-space: normal;
}

</style>