<script>
export default {
  methods: {
    $_notifySuccess (successMessage) {
      this.$vs.notify({
        time: 6000,
        text: successMessage,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success'
      });
    },
    $_notifyFailure (failureMessage) {
      this.$vs.notify({
        time: 6000,
        text: failureMessage,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      });
    },
    $_notifyFailureByResponseData (responseData) {
      if (!responseData) {
        this.$vs.notify({ time: 6000, text: 'Something went wrong.', iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger'});
        return;
      }

      if(responseData.status === 400 && responseData.errors) {
        const errors = responseData.errors;
        for (let mainKey in errors) { 
          for (let i = 0; i < errors[mainKey].length ; i++) {
            // Notification does not respect new line so we'll just separate the notifications.
            this.$vs.notify({ 
              time: 6000,
              text: errors[mainKey][i],
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'});
          }
        }

        

      } else if (responseData.title) {
        this.$vs.notify({ time: 6000, text: responseData.title, iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger'});
      } else {
        this.$vs.notify({ time: 6000, text: 'Something went wrong.', iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger'});
      }

      
    }
  }
}
</script>

<style>

</style>