<template>
  <travio-center-container grid-width="1/2" :pageTitle="pageTitle">
    <vx-card>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Role Name*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" name="roleName" v-model="form.roleName" v-validate="'required|max:50'" :disabled="readOnly"/>
          <span class="text-danger text-sm">{{ errors.first('roleName') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Role Description</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" name="roleDescription" v-model="form.roleDescription" v-validate="'max:150'" :disabled="readOnly" />
          <span class="text-danger text-sm">{{ errors.first('roleDescription') }}</span>
        </div>
      </div>
      <!-- Permissions -->
      <vx-card class="" no-shadow card-border>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="LockIcon" class="mr-2" />
              <span class="font-medium text-lg leading-none">Permissions</span>
            </div>
            <vs-divider class="mb-0" />
          </div>
        </div>

        <div v-for="(category, categoryIndex) in Object.keys(permissionCategories)" :key="categoryIndex">
          <h6 class="mt-6">{{category}}</h6>
          <vs-divider class="mt-3 mb-0" />
          <div class="vx-row" v-for="(permissionName, nameIndex) in Object.keys(getGroupedPermissionNames(category))" :key="nameIndex">
            <div class="vx-col lg:w-1/5 w-full mt-2">
              <span>{{ permissionName }}</span>
            </div>
            <div v-for="(permission, permissionIndex) in getPermissionFunctions(category, permissionName)" 
              :key="permissionIndex" class="vx-col lg:w-1/5 w-full mt-2">
              
              <vs-checkbox :title="permission.description" v-model="form.selectedPermissionIds" :vs-value="permission.id" :disabled="readOnly">
                {{permission.function}}
              </vs-checkbox>  
              
            </div>
            <vs-divider class="mx-4 mb-0 mt-2" />
          </div>
        </div>
      </vx-card>

      <div class="lg:float-left mt-4">
        <span class="text-sm text-danger">*Required Field</span>
      </div>
      <div class="flex flex-wrap justify-end">
        <vs-button v-if="!readOnly" @click="handleCancel" color="danger" class="ml-auto mt-4 mr-4">Cancel</vs-button>
        <vs-button v-if="!readOnly" @click="handleSubmit" class="mt-4">Save</vs-button>
        <vs-button v-if="readOnly && !isSystemRole" @click="handleEditMode" class="ml-auto mt-4 mr-4">Edit</vs-button>
        <vs-button v-if="readOnly" @click="handleCancel" class="mt-4">Back</vs-button>
      </div>

    </vx-card>
  </travio-center-container>

</template>

<script>

import theLodash from 'lodash'
import { Form } from '@/core-modules/form-framework/Form.js'
import roleStore from './roleStore.js'
import { CustomValidator } from './RoleEditorValidator.js'

const customvalidator = new CustomValidator();
customvalidator.setupErrorMessages()

export default {
  // see https://router.vuejs.org/guide/essentials/passing-props.html#object-mode
  props: {
    readOnly: { type: Boolean, default: true }
  },
  data () {
    return {
      form: new Form({
        roleName: '',
        roleDescription: '',
        companyId: -1,
        selectedPermissionIds: []
      })
    }
  },
  // props: [ 'companyId', 'roleId' ],
  computed: {
    pageTitle () {
      return this.readOnly 
        ? 'View Role' 
        : this.$route.params.roleId > 0 ? 'Edit Role' : 'Add Role'
    },
    permissionList () {
      return this.$store.state.roleStore.allPermissions
    },
    permissionCategories () {
      return this.$_.groupBy(this.permissionList, 'category')
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    isSystemRole () {
      return this.$route.query.isSystem;
    }
  },
  methods: {
    getGroupedPermissionNames(categoryName) {
      var filteredPermission = this.$_.filter(this.permissionList,
        (permission) => permission.category === categoryName)
      return this.$_.groupBy(filteredPermission, 'name')
    },
    getPermissionFunctions(categoryName, permissionName) {
      return this.$_.filter(this.permissionList,
        permission => permission.category === categoryName
          && permission.name === permissionName
      )
    },
    handleSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading();
          const action = this.$route.params.roleId > 0 ? 'roleStore/updateRole' : 'roleStore/addRole'
          const payload = this.$route.params.roleId > 0 
            ? { companyId: this.form.companyId, data: this.form.data(), roleId: this.$route.params.roleId }
            : { companyId: this.activeUserInfo.companyId, data: this.form.data() };

          this.$store.dispatch( action, payload).then(response => {
              const successMsg = this.$route.params.roleId > 0 ? 'updated' : 'added new'
              this.$_notifySuccess(`Successfully ${successMsg} role`);
              this.$router.push(`/companies/${this.activeUserInfo.companyId}/roles`)
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        }
      })
    },
    handleCancel () {
      this.$router.push(`/companies/${this.activeUserInfo.companyId}/roles`)
    },
    handleEditMode () {
        this.$router.push(`/companies/${this.activeUserInfo.companyId}/roles/${this.$route.params.roleId}/edit`)
          .catch((error) => { console.error(error) })
    }
  },
  created () {
    if (!roleStore.isRegistered) {
      this.$store.registerModule('roleStore', roleStore)
      roleStore.isRegistered = true

      // If user hasn't gone to the role list yet we need to fetch role list
      this.$store.dispatch('roleStore/fetchRoles', this.activeUserInfo.companyId )
        .catch(err => console.error(err) )
    }
    this.$store.dispatch('roleStore/fetchPermissions' )
        .catch(err => console.error(err))

  },
  mounted () {
    if (this.$route.params.roleId > 0) {
      const companyId = this.isSystemRole ? 0 : this.$route.params.companyId
      
      //Check if user has acccess to the company
      if(!this.isSystemRole &&  this.activeUserInfo.companyId != companyId) {
        this.$router.push('/error-404')
      } else {
        this.$vs.loading()
        this.$http.get(`api/companies/${companyId}/roles/${this.$route.params.roleId}`)
          .then(response => {
            Object.assign(this.form, response.data)
          })
          .catch(error => {
            this.$_notifyFailureByResponseData(error.response.data)
            this.$router.push(`/companies/${this.$route.params.companyId}/roles`)
          })
          .finally(() => this.$vs.loading.close())
      }

    }
  }
}
</script>

<style>

</style>