<template>
  <!-- <div class="flex items-center w-full cell-renderer-select-container">
      <v-select :value="selectedTitle" disabled append-to-body  @input="setSelectedTitle" :options="titleOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
  </div> -->
  <div class="flex items-center">
    <span>{{titleText}}</span>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  name: 'CellRendererSelectTitle',
  components: {
    vSelect
  },
  data () {
    return {
      titleText: '',
    }
  },
  computed: {
    titleOptions () {
      return this.$store.state.lookups.titleOptions
    }
  },
  mounted () {
    const title = this.titleOptions.find(x => x.code == this.params.data.title)
    this.titleText = (title && title.label) || '';
  }
}
</script>

<style scoped>
/* See styling on the parent */
</style>