import axios from "@/axios.js";

export default {
  namespaced: true,
  state: {
    generalSettings : {
      userName: '',
      profilePictureUrl: '',
      title: 0,
      firstName: '',
      lastName: '',
      email: '',
      dateFormat:'d/M/yyyy',//default value
      dateTimeFormat:'d/M/yyyy h:mm a',//default value
      emailConfirmed: false
    },
    personalInfo: {
      dob: null,
      countryCode: "",
      locale: "",
      spokenLanguages: [],
      mobile: ''
    }
  },
  mutations: {
    SET_GENERAL_SETTINGS (state, payload) {
      state.email = payload.email;
      let generalSettings = state.generalSettings;
      generalSettings.userName = payload.userName,
      generalSettings.firstName = payload.firstName,
      generalSettings.lastName = payload.lastName,
      generalSettings.email = payload.email,
      generalSettings.dateFormat = payload.dateFormat ? payload.dateFormat : 'dd/MM/yyyy',//default value
      generalSettings.dateTimeFormat = payload.dateTimeFormat ? payload.dateTimeFormat : 'dd/MM/yyyy HH:mm',//default value
      generalSettings.title = payload.title == 0 ? null : payload.title,
      generalSettings.emailConfirmed = payload.emailConfirmed,
      generalSettings.profilePictureUrl = payload.profilePictureUrl
    },
    SET_PROFILE_PICTURE_URL(state, payload) {
      state.generalSettings.profilePictureUrl = payload.profilePictureUrl
    },
    SET_EMAIL_CONFIRMATION(state, payload) {
      state.generalSettings.emailConfirmed = payload;
    }
  },
  actions: {
    loadAccount({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`api/accounts/generalSettings`)
        .then(response => {
          commit('SET_GENERAL_SETTINGS', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },
    uploadProfilePicture({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(
          'api/accounts/generalSettings/profilePicture',
          payload, {
            headers: { 'Content-Type': 'multipart/form-data'}
          }
        ).then(response => {
          commit('SET_PROFILE_PICTURE_URL', response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
      })
    },
    sendEmailVerification({commit}, token) {
      return new Promise((resolve, reject) => {
        axios.post(
          `api/accounts/generalSettings/emailVerification/${token}`,
          formData
        ).then(response => {
          commit('SET_EMAIL_CONFIRMATION', true);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
      })
    }
  },
  getters: {
    generalSettings(state) {
      return state.generalSettings
    }
  }
}