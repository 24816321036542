import axios from "@/axios.js"
import _ from 'lodash'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    applications: [],
    activeApplication: null,
    regionOptions: {},
    subscriptionOptions: {},
    webPlatformOptions: {},
    languageOptions: {},
    nationalityOptions: {},
    currencyOptions: {}
  },
  mutations: {
    SET_APPLICATION_LIST (state, applications) {
      state.applications = applications
    },
    ADD_APPLICATION (state, application) {
      state.applications.push(application)
    },
    UPDATE_APPLICATION (state, application) {
      const applicationIndex = _.findIndex(state.applications, (item) => item.applicationId === application.applicationId)
      state.applications[applicationIndex] = application
    },
    SET_ACTIVE_APPLICATION(state, application) {
      state.activeApplication = application
    },
    UPDATE_ACTIVE_APPLICATION(state, application) {
      state.activeApplication = application
    },
    SET_REGION_OPTIONS(state, options) {
      state.regionOptions = options
    },
    SET_SUBSCRIPTION_OPTIONS(state, options) {
      state.subscriptionOptions = options
    },
    SET_WEBPLATFORM_OPTIONS(state, options) {
      state.webPlatformOptions = options
    },
    SET_LANGUAGE_OPTIONS(state, options) {
      state.languageOptions = options
    },
    SET_CURRENCY_OPTIONS(state, options) {
      state.currencyOptions = options
    },
    SET_NATIONALITY_OPTIONS(state, options) {
      state.nationalityOptions = options
    }
  },
  actions: {
    fetchApplications ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`api/companies/${payload}/applications`)
        .then(response => {
          commit('SET_APPLICATION_LIST', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },
    addCompanyApplication ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`api/companies/${payload.companyId}/applications`, payload.data)
        .then(response => {
          commit('ADD_APPLICATION', { 
          });
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },
    updateCompanyApplication ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.put(`api/companies/${payload.companyId}/applications/${payload.applicationId}`, payload.data)
        .then(response => {
          commit('UPDATE_APPLICATION', {
          });
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },
    fetchApplicationDetails ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`api/applications/${payload.applicationId}`)
        .then(response => {
          commit('SET_ACTIVE_APPLICATION', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },
    fetchLookups ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`api/companies/${payload.companyId}/applications/lookups`)
        .then(response => {
          commit('SET_REGION_OPTIONS', response.data.regionOptions)
          commit('SET_SUBSCRIPTION_OPTIONS', response.data.subscriptionOptions)
          commit('SET_WEBPLATFORM_OPTIONS', response.data.webPlatformOptions)

          commit('SET_LANGUAGE_OPTIONS', response.data.languages)
          commit('SET_CURRENCY_OPTIONS', response.data.currencies)
          commit('SET_NATIONALITY_OPTIONS', response.data.nationalities)
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },
    updateActiveApplication({ commit, state }, payload) {
      const newVal = Object.assign({}, state.activeApplication, payload)
      commit('SET_ACTIVE_APPLICATION', newVal);
    }
  }
}
