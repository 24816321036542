import axios from "@/axios.js"
import _ from 'lodash'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    companyRoles: [],
    allPermissions: [],
    // Unable to emit event from CellRendererActions so we'll use this as communication
    deleteDialog: { show: true, roleId: 0, affectedUsers: 0 }
  },
  mutations: {
    SET_ROLE_LIST (state, roles) {
      state.companyRoles = roles
    },
    ADD_ROLE (state, role) {
      state.companyRoles.push(role)
    },
    UPDATE_ROLE (state, role) {
      const roleIndex = _.findIndex(state.companyRoles, (item) => item.roleId === role.roleId)
      state.companyRoles[roleIndex] = role
    },
    REMOVE_ROLE (state, roleId) {
      const roleIndex = _.findIndex(state.companyRoles, (item) => item.roleId === roleId)
      state.companyRoles.splice(roleIndex, 1)
    },
    SET_ALLPERMISSION_LIST (state, permissions) {
      state.allPermissions = permissions
    },
    SET_DELETE_DIALOG (state, deleteDialog ) {
      state.deleteDialog = deleteDialog
    }
  },
  actions: {
    fetchRoles ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`api/companies/${payload}/roles`)
        .then(response => {
          commit('SET_ROLE_LIST', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },
    addRole ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`api/companies/${payload.companyId}/roles`, payload.data)
        .then(response => {
          commit('ADD_ROLE', { 
            roleId: response.data,
            roleName: payload.data.roleName,
            roleDescription: payload.data.roleDescription,
            roleType: 'Custom'
          });
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },
    updateRole ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.put(`api/companies/${payload.companyId}/roles/${payload.roleId}`, payload.data)
        .then(response => {
          commit('UPDATE_ROLE', {
            roleId: payload.roleId,
            roleName: payload.data.roleName,
            roleDescription: payload.data.roleDescription,
            roleType: 'Custom'
          });
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },
    removeRole ({ commit }, payload) {
      let queryString = payload.replacementRoleId != null 
        ? `?replacementRoleId=${payload.replacementRoleId}`
        : ''

      return new Promise((resolve, reject) => {
        axios.delete(`api/companies/${payload.companyId}/roles/${payload.roleId}${queryString}`)
        .then(response => {
          commit('REMOVE_ROLE', payload.roleId)
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },
    fetchPermissions ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`api/companies/permissions`)
        .then(response => {
          commit('SET_ALLPERMISSION_LIST', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    }
  }
}