
import navbarSearchAndPinList from "@/layouts/components/navbar/navbarSearchAndPinList"
import themeConfig from "@/../themeConfig.js"
import colors from "@/../themeConfig.js"
import lookups from "./stateLookups.js"

const userDefaults = {
  userId      : 0,  
  displayName : "", 
  photoURL    : '', 
  status      : "",
  userRole    : "",
  companyId   : 0,
  companyLogo : "",
  companyName : ""
}

// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const is_touch_device = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = function(query) {
      return window.matchMedia(query).matches;
  }

  if (('ontouchstart' in window) || window.DocumentTouch) {
      return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

const state = {
  AppActiveUser           : userDefaults,
  Companies               : [],
  Applications            : [],
  ActiveApplicationId     : 0,
  ActiveCompanyId         : 0,
  // Any caller that mutates the active app can skip the redirect to home page,
  // this always gets reset by the ActiveApplicationId watcher in Main.vue
  SkipRedirectAfterAppSwitch: false,
  // Use to enable host admin to directly switch to an application id outside the current company id
  SwitchToApplicationId: 0,
  ActiveCompanyName       : "",
  ActiveApplicationName   : "",
  ActiveApplicationFeatures   : [],
  ActiveApplicationStatsUrl   : null,
  ActiveApplicationStatsUrlLink   : null,
  bodyOverlay             : false,
  isVerticalNavMenuActive : true,
  is_touch_device         : is_touch_device(),
  mainLayoutType          : themeConfig.mainLayoutType || "vertical",
  navbarSearchAndPinList  : navbarSearchAndPinList,
  reduceButton            : themeConfig.sidebarCollapsed,
  verticalNavMenuWidth    : "default",
  verticalNavMenuItemsMin : false,
  scrollY                 : 0,
  starredPages            : navbarSearchAndPinList["pages"].data.filter((page) => page.is_bookmarked),
  theme                   : themeConfig.theme || "light",
  themePrimaryColor       : colors.primary,
  lookups                   : lookups,
  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth: null,
  showFooter: true,
  navMenuItems: []
}

export default state
