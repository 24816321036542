/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

// See https://vuejsdevelopers.com/2017/04/22/vue-js-libraries-plugins/ for best practices

// axios
import axios from './axios.js'
Object.defineProperty(Vue.prototype, '$http', { value: axios });

// lodash - enables us to use this.$_ in every component
import _ from 'lodash'
Object.defineProperty(Vue.prototype, '$_', { value: _ });

// Filters
import './filters/filters.js'


// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// VeeValidate
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')

//Idle timer
import IdleVue from 'idle-vue'
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1200000, //1200000, // 20 minutes
  startAtIdle: false
});

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// Google Oauth2 API
import GAuth from 'vue-google-oauth2'
Vue.use(GAuth, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'profile email',
  prompt: 'select_account'
})

// i18n
import i18n from './localization/i18n'

import PermissionChecker from '@/core-modules/permission-plugin/permission-plugin.js'
Vue.use(PermissionChecker)

import ToastNotification from '@/core-modules/notification-plugin/notification-plugin.js'
Vue.use(ToastNotification)
// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'


Vue.component('VueSlider', VueSlider)

import CKEditor from '@ckeditor/ckeditor5-vue2'
Vue.use(CKEditor)


import Ripple from 'vue-ripple-directive'
Vue.directive('ripple', Ripple);

Vue.config.productionTip = false

import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)

import Autocomplete from 'v-autocomplete'
// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import 'v-autocomplete/dist/v-autocomplete.css'
Vue.use(Autocomplete)

new Vue({
  router,
  store,
  i18n,
  beforeCreate() { 
    this.$store.commit('auth/INIT_ACCESS_TOKENS');
  },
  render: h => h(App)
}).$mount('#app')
