import { Validator } from 'vee-validate';

const dict = {
  custom: {
    roleName: {
      required: 'Role name is required',
      max: "Max length is 50 characters"
    },
    roleDescription: {
      max: "Max length is 150 characters"
    },
  }
};

export class CustomValidator {
  constructor() {}
  setupErrorMessages ()  {
    Validator.localize('en', dict);
  }
}
