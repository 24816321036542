import axios from "@/axios.js"
import _ from 'lodash'
import state from "../../store/state"

export default {
  namespaced: true,
  state: {
    activeWidget: JSON.parse(localStorage.getItem('active_application_widget')) || null,
    allWidgets: [],
    createdWidgets: [],
    myWidgets: [
      { widgetId: 1, categoryId: 3, name: 'Demo Booking Flow', url: 'demo-booking-flow', description: 'Standard booking flow for all search types', thumbnail: 'https://widgets.widg.io/widgetassets/hero/age-verification-1.png', icon: 'https://widgets.widg.io/widgetassets/icons/age-verification.png'},
      { widgetId: 2, categoryId: 1, name: 'travel-booking', url: 'travel-booking', description: 'Standard booking flow', thumbnail: 'https://widgets.widg.io/widgetassets/hero/age-verification-1.png', icon: 'https://widgets.widg.io/widgetassets/icons/age-verification.png' },
      { widgetId: 4, categoryId: 4, name: 'facebook-chat', url: 'facebook-chat', description: 'Standard booking flow', thumbnail: 'https://widgets.widg.io/widgetassets/hero/age-verification-1.png', icon: 'https://widgets.widg.io/widgetassets/icons/age-verification.png' },
      { widgetId: 6, categoryId: 1, name: 'email-signup', url: 'email-signup', description: 'Standard booking flow', thumbnail: 'https://widgets.widg.io/widgetassets/hero/age-verification-1.png', icon: 'https://widgets.widg.io/widgetassets/icons/age-verification.png' }
    ],
    categories: [
      // { id: 1, name: 'Searchbox'},
      // { id: 2, name: 'Search Results'},
      // { id: 3, name: 'Search Maps'},
      // { id: 4, name: 'Offers/Deals'},
      // { id: 5, name: 'Entire Booking Flows'},
    ],
    widgetCategories: [],
    fieldSettings: {},
    presets: {}

  },
  mutations: {
    SET_ALL_WIDGETS_LIST (state, widgets) {
      state.allWidgets = widgets
    },
    SET_CREATED_WIDGETS_LIST (state, widgets) {
      state.createdWidgets = widgets
    },
    SET_CATEGORIES (state, categories) {
      state.widgetCategories = categories
    },
    SET_PRESETS (state, presets) {
      state.presets = presets
    },
    SET_FIELD_SETTINGS (state, fieldSettings) {
      state.fieldSettings = fieldSettings
    }
  },
  actions: {
    fetchAllWidgets ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`api/widgets`)
          .then(response => {
            commit('SET_ALL_WIDGETS_LIST', response.data.widgets);
            commit('SET_CATEGORIES', response.data.categories);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
    fetchCreatedWidgetsByApp ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`api/widgets/apps/${payload.applicationId}/instances`)
          .then(response => {
            commit('SET_CREATED_WIDGETS_LIST', response.data);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
    fetchWidgetInstances ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`api/widgets/apps/${payload.applicationId}/${payload.widgetUrl}`)
          .then(response => {
            commit('SET_WIDGET_INSTANCES', response.data);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
  }
}