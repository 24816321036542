<template>
  <div v-if="!useCustomDate">
    <div class="vx-row mb-4">
      <div class="vx-col sm:w-1/5 w-full">
        <span>Month*</span>
      </div>
      <div class="vx-col sm:w-4/5 w-full">
        <v-select v-model="form.selectedYearMonth" :reduce="x=>x.code" @input="setSelectedYearMonth" :options="yearMonthOptions" name="Year Month" />
        <!-- <span class="text-danger text-sm">{{ errors.first('Year Month') }}</span> -->
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { Form } from '@/core-modules/form-framework/Form.js'

export default {
  name: 'top-search-location-editor',
  // props: ['formData'],
  props: {
    useCustomDate: { required: false, default: false },
    formData: { type: Object, required: false },
  },
  components: {
    vSelect
  },
  data () {
    return {
      form: new Form({
        selectedYearMonth: null, // '2024-03'
      }),      
      selectedYearMonth : null,
      yearMonthOptions: []
    }
  },
  computed: {   
    searchTypeOptions () {
      return this.$store.state.lookups.searchTypeOptions
    },
    additionalSettings () {
      return JSON.parse(JSON.stringify(this.formData))
    },
    validateAll () {
      // https://www.tutorialfor.com/blog-266572.htm
      // Parent component will access this to include in validation before submission
      // Note that this will return a promise
      return this.$validator.validateAll();
    }
  },
  mounted() {
    let yearMonthOptions = [];
    const currentDate = new Date();
    for (let i = 0; i < 13; i++) {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
        yearMonthOptions.push(this.formatDate(date));
    }
    this.yearMonthOptions = yearMonthOptions;
  },
  watch: {
    formData (newVal, oldVal) {
      const { selectedYearMonth } = newVal
      this.form.selectedYearMonth = selectedYearMonth

      var found = this.yearMonthOptions && this.yearMonthOptions.find(x => x.code == selectedYearMonth);
      if (!found) {
        this.yearMonthOptions.push(
          { code: selectedYearMonth, label: this.formatYearMonth(selectedYearMonth) }
        );
      }
    },
    useCustomDate (newVal, oldVal) {
      if (newVal) {
        this.form.selectedYearMonth = null;
        this.$emit('changed', this.form.data());
      }
    }
  },
  methods: {
    
    setSelectedYearMonth (value) {
      // this.form.selectedYearMonth = value ? value.code : null
      this.$emit('changed', this.form.data());
    },
    formatDate (date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // getMonth is zero-indexed
      const code = `${year}-${month.toString().padStart(2, '0')}`;
      const label = date.toLocaleString('default', { month: 'short', year: 'numeric' });
      return { code, label };
    },
    
    formatYearMonth(yearMonth) {
      // Input: "2024-03", Output: "Mar 2024"
        const [year, month] = yearMonth.split('-').map(Number);

        // Create a Date object using the year and month
        const date = new Date(year, month - 1);

        // Format the date as "MMM yyyy"
        const formattedDate = date.toLocaleString('default', { month: 'short', year: 'numeric' });

        return formattedDate;
    },
  }
}
</script>

<style>

</style>