/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js";
const actions = {

    // /////////////////////////////////////////////
    // COMPONENTS
    // /////////////////////////////////////////////

    // Vertical NavMenu
    updateVerticalNavMenuWidth({ commit }, width) {
      commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    // The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    // /////////////////////////////////////////////
    // UI
    // /////////////////////////////////////////////

    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val)
    },

    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    updateUserInfo({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get('api/auth/activeUser')
        .then((response) => {
          commit('UPDATE_USER_INFO', response.data)
          // commit('UPDATE_ACTIVE_COMPANY', { id: response.data.companyId, name: response.data.companyName })
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
      })
    },
    updateUserInfoExplicitCompany({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`api/auth/activeUser/${payload.companyId}`)
        .then((response) => {
          commit('UPDATE_USER_INFO', response.data)
          // commit('UPDATE_ACTIVE_COMPANY', { id: payload.companyId, name: response.data.companyName })
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
      })
    }
}

export default actions
