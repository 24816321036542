<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
        <feather-icon title="View Details" icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="displayRecord" />
        <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
        <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'
export default {
  name: 'CellRendererActions',
  mixins: [TpNotificationMixin],
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    editRecord () {
      this.$router.push(`/companies/${this.activeUserInfo.companyId}/users/${this.params.data.userId}/edit`)
        .catch((error) => { console.error(error) })
    },
    confirmDeleteRecord () {

      if(this.params.data.userId == this.activeUserInfo.userId){
        this.$_notifyFailure('Deleting your account is not allowed.')
        return
      }

      if(this.params.data.isHostAdmin){
        this.$_notifyFailure('Deleting admin account is not allowed.')
        return
      }

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Delete user ${this.params.data.email}`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    deleteRecord () {
      this.$vs.loading()
      this.$store.dispatch('userStore/removeUser', { 
        userId: this.params.data.userId,
        companyId: this.activeUserInfo.companyId
      })
      .then(response => {
        this.$_notifySuccess('Successfully deleted user')
      })
      .catch(error => {
        this.$_notifyFailureByResponseData(error.response.data)
      })
      .finally(() => this.$vs.loading.close())
    },
    displayRecord () {
      this.$router.push({ name: 'company-users-view', params: { 
        companyId: this.activeUserInfo.companyId,
        userId: this.params.data.userId }
      })
      .catch((error) => { console.error(error) })
    },
  }
}
</script>
