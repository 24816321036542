
import account from './data/account/account'

export default {
  en: {
    account: account.en
  },
  de: {
    account: account.de
  },
  fr: {
    account: account.fr
  }
}