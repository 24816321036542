<template>
  <div class="mb-2">
    <vs-avatar 
      :src="this.params.data.logoUrl"
      :text="this.params.data.applicationName" 
      class="application-logo-avatar flex-shrink-0 mr-2" 
      size="35px"
      />
  </div>

</template>

<script>
export default {
  name: 'CellRendererAppLogo'
}
</script>
