import _ from 'lodash'

export default {
  // called by Vue.use(FirstPlugin)
  install(Vue, options) {
    Vue.prototype.$hasPermission = function (requiredPermissions, userPermissions) {
      let result = true
      if (requiredPermissions) {
        const permissions = requiredPermissions.split(',')
        result = _.find(permissions, (p) => { 
          return _.includes(userPermissions, p.trim())
        });
      }
      return result;
    }

  }
}