import axios from "@/axios.js"
import _ from 'lodash'
import { startOfYesterday } from "date-fns"

const state = {
  companyUsers: [],
  companyUsersBulkInsert: JSON.parse(localStorage.getItem('companyUsersBulkInsert')) || [],
  companyRoleOptions: []
}

const mutations = {
  SET_USER_LIST (state, users) {
    state.companyUsers = users
  },
  ADD_USER (state, user) {
    state.companyUsers.push(user)
  },
  UPDATE_USER (state, user) {
    const userIndex = _.findIndex(state.companyUsers, (item) => item.userId === user.userId)
    // Last login and create date not on the user payload, so we'll retain those
    state.companyUsers[userIndex] = Object.assign(state.companyUsers[userIndex], user)
  },
  REMOVE_USER (state, userId) {
    const userIndex = _.findIndex(state.companyUsers, (item) => item.userId === userId)
    state.companyUsers.splice(userIndex, 1)
  },
  SET_COMPANY_ROLE_OPTIONS (state, companyRoles) {
    state.companyRoleOptions = companyRoles
  },
  SET_USER_BULK_INSERT_LIST (state, companyUsersBulkInsert) {
    state.companyUsersBulkInsert = companyUsersBulkInsert
  },
  REMOVE_USER_BULK_INSERT_LIST (state) {
    state.companyUsersBulkInsert = []
  },
}

const actions = {
  fetchUsers ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`api/companies/${payload}/users`)
      .then(response => {
        commit('SET_USER_LIST', response.data);
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    })
  },
  addUser ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`api/companies/${payload.companyId}/users`, payload.data)
      .then(response => {
        commit('ADD_USER', response.data);
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    })
  },
  updateUser ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`api/companies/${payload.companyId}/users/${payload.userId}`, payload.data)
      .then(response => {
        commit('UPDATE_USER', {
          // This is not the complete set of fields, we'll merge with existing props via Object.assign
          userId: payload.userId,
          email: payload.data.email,
          fullName: payload.data.firstName + ' ' + payload.data.lastname,
          roleTitle: payload.data.roleTitle,
          isActive: payload.data.isActive
        });
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    })
  },
  removeUser ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`api/companies/${payload.companyId}/users/${payload.userId}`)
      .then(response => {
        commit('REMOVE_USER', payload.userId)
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    })
  },
  fetchRoleOptions ({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      axios.get(`api/companies/${companyId}/users/roleoptions`)
      .then(response => {
        const roleOptions = _.map(response.data, (x) => { 
          return { code: x.roleId, label: x.roleName }
        })
        commit('SET_COMPANY_ROLE_OPTIONS', roleOptions)
        resolve(response);
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  saveBulkInsertItems ({ commit }, payload) {
    localStorage.setItem('companyUsersBulkInsert', JSON.stringify(payload));
    commit('SET_USER_BULK_INSERT_LIST', payload);
    return new Promise(resolve => resolve(true))
  },
  submitBulkInsertItems({ commit }, payload ) {
    return new Promise((resolve, reject) => {
      axios.post(`api/companies/${payload.companyId}/users/bulkInsert`, { users: payload.users })
      .then(response => {
        localStorage.removeItem('companyUsersBulkInsert');
        commit('REMOVE_USER_BULK_INSERT_LIST')
        resolve(response);
      })
      .catch((error) => {
        reject(error)
      })
    })
  }
}
export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions
}