<template>
    <vs-chip class="ag-grid-cell-chip" :color="chipColor(params.data.roleType)">
      <span>{{ params.data.roleType }}</span>
    </vs-chip>
</template>

<script>
export default {
  name: 'CellRendererRoleType',
  computed: {
    chipColor () {
      return (value) => {
        if (value && value.toLowerCase() === 'custom') return 'success'
        else if (value && value.toLowerCase() === 'system') return 'danger'
        else return 'primary'
      }
    }
  }
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger),.15);
    color: rgba(var(--vs-danger),1) !important;
    font-weight: 500;
  }
}
</style>
