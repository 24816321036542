<template>
  <div>
    <feather-icon v-if="userHasPermission('company_apps_edit')" title="View Details" icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="displayRecord" />
    <feather-icon v-if="userHasPermission('company_apps_edit')" title="Edit" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onEdit" />
  </div>
</template>

<script>
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'
import vSelect from 'vue-select'
import _ from 'lodash'
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';

export default {
  name: 'CellRendererActions',
  components: {
    vSelect
  },
  mixins: [TpNotificationMixin],
  data () {
    return {
      
    }
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    onEdit () {
      this.$router.push({ name: 'company-applications-editor', 
        params: { 
          companyId: this.activeUserInfo.companyId,
          applicationId: this.params.data.applicationId
        }
      })
    },
    displayRecord () {
    }
  }
}
</script>
