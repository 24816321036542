// axios
import axiosStatic from 'axios'
import store from '@/store/store.js'
import router from '@/router.js'

const axios = axiosStatic.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_API_BASE_URL
})

axios.interceptors.response.use(response => {
  // Return a successful response back to the calling service
  return response;
}, (error) => {
  // Return any error which is not due to authentication back to the calling service
  if (error.response.status !== 401) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  // Logout user if token refresh didn't work or user is disabled
  if (error.config.url === '/api/auth/refreshToken') {
    
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  // Try request again with new token
  return store.dispatch('auth/refreshToken')
    .then((data) => {

      // New request with new token
      const config = error.config;
      config.headers['Authorization'] = `Bearer ${data.acessToken}`;

      return new Promise((resolve, reject) => {
        axios.request(config).then(response => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        })
      });

    })
    .catch((error) => {
      store.dispatch('auth/logout')
        .then(result => {
          // This is no longer needed since user is already redirected at this point  when the auto logout kicks in
          // router.push({ name: 'page-login' }) 
        })
    });
});

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token')

    if (token && (config.url.startsWith('api/') || config.url.startsWith('/api/'))) {
      config.headers['Authorization'] = `Bearer ${ token }`
    }
    
    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

export default axios;
