const account = {
  generalSettings: {
    titleLabel: 'Title',
    firstNameLabel: 'First Name',
    lastNameLabel: 'Last Name',
    emailLabel: 'Email',
    dateFormatLabel: 'Date Format',
    saveBtnText: 'Save Changes'
  },
  security: {
    twoFactorHeading: 'Set up Two-Factor Authentication',
    twoFactorEnableLabel: 'Enable two-factor authentication (2FA) on this account'
  }

}

export default account;