var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.useCustomDate
    ? _c("div", [
        _c("div", { staticClass: "vx-row mb-4" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("v-select", {
                attrs: {
                  reduce: (x) => x.code,
                  options: _vm.yearMonthOptions,
                  name: "Year Month",
                },
                on: { input: _vm.setSelectedYearMonth },
                model: {
                  value: _vm.form.selectedYearMonth,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "selectedYearMonth", $$v)
                  },
                  expression: "form.selectedYearMonth",
                },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
      _c("span", [_vm._v("Month*")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }