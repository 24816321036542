<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card>

        <div class="vx-row">
          <div class="vx-col xs:w-full lg:w-3/4">
            <div class="flex flex-wrap items-center">
              <vs-button @click="handleSubmit" class="mr-2 sm:mb-0">Submit</vs-button>
              <vs-button @click="showRolePrompt=true" color="primary" class="mr-2 sm:mb-0" type="border" >Set All Roles</vs-button>
            </div>
          </div>
          <div class="vx-col xs:w-full lg:w-1/4">
            <vs-input class="float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2" 
            v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
          </div>
        </div>
        
        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="usersData"
          :gridOptions="gridOptions"
          rowSelection="single"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @selection-changed="onSelectionChanged"
        >
        </ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="5"
          v-model="currentPage" />
      </vx-card>

      <vs-prompt
        title="Set All Roles"
        @cancel="clearRolePrompt"
        @close="clearRolePrompt"
        @accept="setAllRoles"
        :active.sync="showRolePrompt">
        <div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <p>Select role to set all users</p>
            </div>

            <div class="vx-col w-full mt-4">
              <label class="text-sm">Title</label>
              <v-select v-model="selectedRole" :options="roleOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            </div>
          </div>

          <!-- <vs-button @click="clearRolePrompt" color="danger" type="flat" class="ml-auto mt-6">Cancel</vs-button>
          <vs-button @click="fillInMissingRoles" class="float-right ml-auto mt-6">OK</vs-button> -->
      
        </div>
      </vs-prompt>
    </div>
  </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import { formatISO } from 'date-fns'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererSelectRole from './cell-renderers/CellRendererSelectRole.vue'
import CellRendererSelectTitle from './cell-renderers/CellRendererSelectTitle.vue'
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'
import userStore from './userStore.js'
import Vue from "vue"
import vSelect from 'vue-select'

export default {
  components: {
    AgGridVue,
    CellRendererSelectRole,
    CellRendererSelectTitle,
    vSelect
  },
  props: ['newUserList'],
  mixins: [TpNotificationMixin],
  data () {
    return {
      dummyData: '',
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      usersData: [],
      cellRendererComponents: {
        CellRendererSelectRole,
        CellRendererSelectTitle
      },
      searchQuery: '',
      showRolePrompt: false,
      selectedRole: {}
    }
  },
  computed: {
    roleOptions () {
      return this.$store.state.userStore.companyRoleOptions
    },
    activeUserInfo () {
      return this.$store.state.AppActiveUser;
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  beforeMount() {
    // Only necessary if user list screen has not been navigated to yet
    if (!userStore.isRegistered) {
      this.$store.registerModule('userStore', userStore)
      userStore.isRegistered = true
    }
    this.usersData = JSON.parse(JSON.stringify(this.$store.state.userStore.companyUsersBulkInsert))
    // Load role option in vuex for cell renderer and role prompt
    this.$store.dispatch('userStore/fetchRoleOptions', this.$route.params.companyId )
      .catch(err => console.error(err) )

    this.gridOptions = {};
      // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
      this.columnDefs =  [
        { headerName: 'Email', field: 'email', sortable: true, suppressSizeToFit: true, width: 250 },
        { headerName: 'First Name', field: 'firstName', sortable: true, suppressSizeToFit: true, width: 250 },
        { headerName: 'Last Name', field: 'lastName', sortable: true, suppressSizeToFit: true, width: 250 },
        { headerName: 'Title', field: 'title', sortable: true, suppressSizeToFit: true, width: 120, cellRendererFramework: Vue.extend(CellRendererSelectTitle) },
        { headerName: 'Role Title', field: 'roleTitle', sortable: true, suppressSizeToFit: true, width: 200 },
        { headerName: 'Role', field: 'role', sortable: true, suppressSizeToFit: false, width: 230, cellRendererFramework: Vue.extend(CellRendererSelectRole) }
      ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  
  methods: {
    handleSubmit () {
      this.$vs.loading();
      this.$store.dispatch('userStore/submitBulkInsertItems', { companyId: this.$route.params.companyId, users: this.usersData })
      .then(response => {
        if(response.data) {
          this.$_notifySuccess(`Successfully added ${response.data} user(s)`)
          this.$router.push({ name: 'company-users', params: { companyId: this.$route.params.companyId } })
        } else {
          this.$_notifyFailure('No users have been added')
        }
      })
      .catch((error) => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows();
    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    setAllRoles () {
      const roleId = this.selectedRole.code
      this.$_.forEach(this.usersData, (el, index) => {
        el.roleId = roleId
      })

      // Refresh grid to refresh CellRenderer for roles
      this.gridApi.refreshCells({ force: true, suppressFlash: true });
      this.showRolePrompt = false
      this.selectedRole = {}
    },
    clearRolePrompt () {
      this.selectedRole = {}
    }
  }
}
</script>

