<template>
  <travio-center-container pageTitle="Edit Dashboard" gridWidth="1/2">
    <vx-card>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Name*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" name="Dashboard Name" v-model="dashboardName" v-validate="'required|max:50'"/>
          <span class="text-danger text-sm">{{ errors.first('Dashboard Name') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Description</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" name="dashboardDescription" v-model="dashboardDescription" v-validate="'max:150'" />
          <span class="text-danger text-sm">{{ errors.first('dashboardDescription') }}</span>
        </div>
      </div>

      <vx-card class="mt-base" no-shadow card-border>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="ArrowDownIcon" class="mr-2" />
              <span class="font-medium text-lg leading-none">Widget Sorting</span>
            </div>
            <vs-divider class="mb-4" />
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-list>
              <draggable :list="widgetList" class="cursor-move">
                <vs-list-item class="flex items-center p-1" v-for="(widget, index) in widgetList" 
                  :key="index" 
                  :title="widget.name"
                  :subtitle="widget.title">
                    <feather-icon slot="avatar" icon="MoreVerticalIcon" class="float-left" svgClasses="w-6 h-6 text-grey"></feather-icon>
                    <vs-avatar slot="avatar" :text="widget.name" />
                </vs-list-item>
              </draggable>
            </vs-list>
          </div>
        </div>

      </vx-card>

      <div class="float-left mt-6">
        <span class="text-sm text-danger">*Required Field</span>
      </div>
      <div class="flex flex-wrap justify-end">
        <vs-button @click="handleCancel" color="danger" class="mt-4 mr-4">Cancel</vs-button>
        <vs-button @click="handleSubmit" class="mt-4 mr-4">Save</vs-button>
      </div>


    </vx-card>
  </travio-center-container>
</template>

<script>

import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'
import theLodash from 'lodash'
import companyDashboardStore from './companyDashboardStore.js'
import vSelect from 'vue-select'
import draggable from 'vuedraggable'

export default {
  mixins: [TpNotificationMixin],
  props: {
    dashboardId: { type: Number, required: true },
    isCompanyDashboard: { type: Boolean, required: true },
    applicationId: { type: Number }
  },
  components: {
    vSelect,
    draggable
  },
  data () {
    return {
      dashboardName: '',
      dashboardDescription: '',
      widgetList: []
    }
  },
  computed: {
    dashboardStore () {
      return this.isCompanyDashboard
				? this.$store.state.companyDashboardStore
				: this.$store.state.applicationDashboardStore
    },
    activeDashboard () {
      return this.dashboardStore.activeDashboard
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  mounted () {
    if (this.dashboardStore && this.activeDashboard && this.dashboardStore.widgets) {
      
      const list = this.$_.sortBy(this.dashboardStore.widgets, ['orderId'])
      this.widgetList = JSON.parse(JSON.stringify(list))

      const activeDashboard = JSON.parse(JSON.stringify(this.dashboardStore.activeDashboard))
      this.dashboardName = activeDashboard.name
      this.dashboardDescription = activeDashboard.description
    }
    
  },
  methods: {
    handleSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const payload = {
            companyId: this.activeUserInfo.companyId,
            dashboardId: this.dashboardId,
            dashboardName: this.dashboardName,
            dashboardDescription: this.dashboardDescription,
            sortedWidgetInstanceIds: this.$_.map(this.widgetList, x => x.instanceId),
            applicationId: this.applicationId
          }

          const routeName = this.isCompanyDashboard ? 'company-dashboard' : 'application-dashboard'
          const params = this.isCompanyDashboard ? { dashboardId: this.dashboardId } : {}
          const storeAction = this.isCompanyDashboard ? 'companyDashboardStore/updateDashboard' : 'applicationDashboardStore/updateDashboard'

          this.$vs.loading()
          this.$store.dispatch(storeAction, payload)
            .then(response => {
              this.$router.push({ name: routeName, params: params })
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        }
      })
    },
    handleCancel () {
      if(this.isCompanyDashboard) {
        this.$router.push(`/companies/${this.activeUserInfo.companyId}`)
      } else {
        this.$router.push(`/applications/${this.applicationId}`)
      }
      
    }
  },

}
</script>

<style>

</style>