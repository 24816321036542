import axios from "../../axios";

export default {
  namespaced: true,
  state: {
    accessToken: localStorage.getItem('access_token') || null,
    refreshToken: localStorage.getItem('refresh_token') || null,
    oneTimeTwoFactorToken: localStorage.getItem('one_time_twofactor_token') || null,
    userPermissions: (localStorage.getItem('user_permissions') 
      && JSON.parse(localStorage.getItem('user_permissions'))) || null
  },
  mutations: {
    INIT_ACCESS_TOKENS (state) {
      state.accessToken = localStorage.getItem('access_token') || null;
      state.refreshToken = localStorage.getItem('refresh_token') || null;
      state.oneTimeTwoFactorToken = localStorage.getItem('one_time_twofactor_token') || null;
      state.userPermissions = (localStorage.getItem('user_permissions') 
        && JSON.parse(localStorage.getItem('user_permissions'))) || null
    },
    SET_ACCESS_TOKENS(state, tokens) {
      state.accessToken = tokens.accessToken;
      state.refreshToken = tokens.refreshToken;
      state.userPermissions = tokens.userPermissions;
    },
    SET_USER_PERMISSIONS(state, permissions) {
      state.userPermissions = permissions
    },
    SET_ONE_TIME_TWOFACTOR_TOKEN (state, oneTimeToken) {
      state.oneTimeTwoFactorToken = oneTimeToken;
    },
    REMOVE_ONE_TIME_TWOFACTOR_TOKEN (state) {
      state.oneTimeTwoFactorToken = null;
    },
    DESTROY_ACCESS_TOKENS(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.userPermissions = null;
      state.oneTimeTwoFactorToken = null;
    }
  },
  actions: {
    persistTokens ({ commit }, payload) {
      commit('SET_ACCESS_TOKENS', { 
        accessToken: payload.token,
        refreshToken: payload.refreshToken,
        userPermissions: payload.userPermissions
      });

      localStorage.setItem('access_token', payload.token);
      localStorage.setItem('refresh_token', payload.refreshToken);
      localStorage.setItem('last_active_datetime', new Date());
      if(payload.userPermissions) {
        localStorage.setItem('user_permissions', JSON.stringify(payload.userPermissions));
      }
    },
    persistOneTimeTwofactorToken ({commit}, payload) {
      localStorage.setItem('one_time_twofactor_token', payload.oneTimeTwoFactorToken);
      commit('SET_ONE_TIME_TWOFACTOR_TOKEN', payload.oneTimeTwoFactorToken);
    },
    removeOneTimeTwofactorToken ({commit}, payload) {
      localStorage.removeItem('one_time_twofactor_token');
      commit('REMOVE_ONE_TIME_TWOFACTOR_TOKEN');
    },
    loginWithPassword ({ dispatch, commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('api/auth/token', {
          'username': payload.email,
          'password': payload.password
        })
        .then((response) => {
          if (response.data.needsTwoFactorCode) {
            resolve(response)
            return
          }
          const token = response.data.accessToken;
          const refreshToken = response.data.refreshToken;
          const userPermissions = response.data.userPermissions;
          dispatch('persistTokens', {token, refreshToken, userPermissions})
          commit('UPDATE_ACTIVE_COMPANY', { id: response.data.selectedCompanyId, name: response.data.selectedCompanyName }, { root: true })
          resolve(response) 
        })
        .catch(error => {
          reject(error);
        });
      })
    },
    loginWithFacebook ({ dispatch, commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('api/auth/token/facebook', {
          'accessToken': payload.facebookToken
        })
        .then((response) => {
          const token = response.data.accessToken;
          const refreshToken = response.data.refreshToken;
          const userPermissions = response.data.userPermissions;
          dispatch('persistTokens', {token, refreshToken, userPermissions})
          commit('UPDATE_ACTIVE_COMPANY', { id: response.data.selectedCompanyId, name: response.data.selectedCompanyName }, { root: true })
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
      })
    },
    loginWithGoogle ({ dispatch, commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('api/auth/token/google', {
          'idToken': payload.googleToken
        })
        .then((response) => {
          const token = response.data.accessToken;
          const refreshToken = response.data.refreshToken;
          const userPermissions = response.data.userPermissions;
          dispatch('persistTokens', {token, refreshToken, userPermissions})
          commit('UPDATE_ACTIVE_COMPANY', { id: response.data.selectedCompanyId, name: response.data.selectedCompanyName }, { root: true })
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
      })
    },
    loginWithTwitter ({ dispatch, commit }, payload) {
      const token = payload.accessToken;
      const refreshToken = payload.refreshToken;
      const selectedCompanyId = payload.selectedCompanyId;
      const userPermissions = null;
      dispatch('persistTokens', {token, refreshToken, userPermissions})
      commit('UPDATE_ACTIVE_COMPANY', { id: selectedCompanyId, name: null }, { root: true })
    },
    // Applies to twitter login only, we can't fetch user permission during login
    updateUserPermissions ({ commit }, payload) {
      commit('SET_USER_PERMISSIONS', payload)
      localStorage.setItem('user_permissions', JSON.stringify(payload));
    },
    logout(context, payload) {
      if (context.getters.isLoggedIn) {
        return new Promise((resolve, reject) => {
          try {
            // clear all local storage except 'tours'
            var toursItem = localStorage.getItem('tours');
            localStorage.clear();
            localStorage.setItem('tours',toursItem);
            context.commit('DESTROY_ACCESS_TOKENS');
            resolve('success');
          } catch (error) {
            reject(error);
          }
        })
      }
    },
    refreshToken ({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        axios.post('api/auth/refreshToken', {
          'accessToken': state.accessToken,
          'refreshToken' : state.refreshToken
        }).then(response => {
          const token = response.data.accessToken;
          const refreshToken = response.data.refreshToken;
          const userPermissions = response.data.userPermissions;
          dispatch('persistTokens', {token, refreshToken, userPermissions})
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },
    registerCompany (context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/companyInterest', { ...payload }) 
          .then(response => resolve(response))
          .catch(error => reject(error));
      })
    },
    switchCompany ({ dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('api/auth/switchToken', {
          'accessToken': state.accessToken,
          'refreshToken' : state.refreshToken,
          'companyId' : payload.companyId
        }).then(response => {
          // replace token with the new claims
          // replace userPermissions
          const token = response.data.accessToken;
          const refreshToken = response.data.refreshToken;
          const userPermissions = response.data.userPermissions;
          dispatch('persistTokens', {token, refreshToken, userPermissions})
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
      })
      
      // replace app list
      // switch to a new app context
      // clear local storage
    }
  },
  getters: {
    isLoggedIn(state) {
      return state.accessToken !== null;
    },
    getAccessToken(state) {
      return state.accessToken;
    },
    getRefreshToken(state) {
      return state.refreshToken;
    },
    getUserPermissions(state) {
      return state.userPermissions;
    }
  }
}