<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <div v-if="userHasPermission('company_apps_create')" class="vx-row">
          <div class="vx-col w-full">
            <vs-button @click="onAdd" disabled class="mr-4 sm:mb-0 mb-2">Add</vs-button>
            <!-- <vs-button @click="deleteRoles" type="border" color="danger" class="mr-4 sm:mb-0 mb-4">Delete</vs-button> -->
            <vs-input class="float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
          </div>
        </div>

        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="applicationData"
          :gridOptions="gridOptions"
          rowSelection="single"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @selection-changed="onSelectionChanged"
        >
        </ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="10"
          v-model="currentPage" />
      </vx-card>
    </div>
  </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

import CellRendererAppName from './cell-renderers/CellRendererAppName.vue'
import CellRendererActions from './cell-renderers/CellRendererActions.vue'
import CellRendererAppLogo from './cell-renderers/CellRendererAppLogo.vue'

import CellRendererCheckbox from './cell-renderers/CellRendererCheckbox.vue'
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'
import companyApplicationStore from './companyApplicationStore.js'
import Vue from "vue"
import { format } from 'date-fns'
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';

export default {
  components: {
    AgGridVue,
    CellRendererAppName,
    CellRendererActions,
    CellRendererAppLogo,
    CellRendererCheckbox
  },
  mixins: [TpNotificationMixin],
  data () {
    return {
      dummyData: '',
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererAppName,
        CellRendererActions,
        CellRendererCheckbox
      },
      searchQuery: ''
    }
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    applicationData () {
      // return [
      //   {applicationId: 1, applicationName: 'App 1', region: 'UK South', isDemo: false, isActive: true, createdDate: Date.now(), logoUrl: 'https://storagetravioprodev.blob.core.windows.net/companies/users/24-C781610D-3C81-4FD4-85A6-0A8C15E55C89?version=GcNoHcQv' },
      //   {applicationId: 2, applicationName: 'Application 2', region: 'UK Nort', isDemo: true, isActive: true, createdDate: Date.now(), logoUrl: '' },
      //   {applicationId: 3, applicationName: 'App 3', region: 'UK West', isDemo: false, isActive: true, createdDate: Date.now(), logoUrl: ''} 
      // ]
      return this.$store.state.companyApplicationStore.applications
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  created () {
    if (!companyApplicationStore.isRegistered) {
      this.$store.registerModule('companyApplicationStore', companyApplicationStore)
      companyApplicationStore.isRegistered = true
    }
    this.$store.dispatch('companyApplicationStore/fetchApplications', this.$route.params.companyId )
      .catch(err => console.error(err) )
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'applicationId', hide: true },
      { headerName: 'Actions', width: 120, sortable: false, suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererActions) },
      { headerName: 'Logo', field: 'logo', width: 100, suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererAppLogo)},
      { headerName: 'Application Name', field: 'applicationName', width: 390, suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererAppName)},
      { headerName: 'Data Centre', field: 'region', width: 200, suppressSizeToFit: true },
      { headerName: 'Is Active', field: 'isActive', sortable: true, width: 150, suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererCheckbox) },
      { 
        headerName: 'Created',
        field: 'createdDate',
        sortable: true,
        suppressSizeToFit: true,
        width: 180,
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateTimeFormat)
      },
      { field: 'logoUrl', hide: true },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  
  methods: {
    onAdd () {
      this.$router.push({ name: 'company-applications-addition', params: { companyId: this.activeUserInfo.companyId}})
    },

    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows();
    },
    onGridReady(params) {

    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    }
  }
}
</script>

<style>

</style>
