/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store'
import CompanySettings from '@/app/company-settings/CompanySettings.vue'
import RoleList from '@/app/company-roles/RoleList.vue'
import RoleEditor from '@/app/company-roles/RoleEditor.vue'
import UserList from '@/app/company-users/UserList.vue'
import ApplicationList from '@/app/company-applications/ApplicationList.vue'
import ApplicationCreate from '@/app/company-applications/ApplicationCreate.vue'
import UserBulkInsert from '@/app/company-users/UserBulkInsert.vue'
import UserEditor from '@/app/company-users/UserEditor.vue'
import CompanyDashboardEditor from '@/app/company-dashboard/CompanyDashboardEditor.vue'
import DashboardWidgetEditor from '@/app/company-dashboard/DashboardWidgetEditor.vue'
import _ from 'lodash'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [

    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      meta: {
        requresAuth: true
      },
      children: [
        // =============================================================================
        // Feature Routes
        // =============================================================================
        {
          path: '/',
          name: 'home',
          component: () => import('@/app/home/Home.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', active: true }
            ],
            pageTitle: 'Home'
          }
        },
        {
          path: '/account',
          name: 'account',
          component: () => import('@/app/account/Account.vue')
        },
        {
          path: '/account/twitterSignupRedirect',
          name: 'account-twitter-signup-redirect',
          component: () => import('@/app/account/AccountTwitterSignupRedirect.vue')
        },
        {
          path: '/account/selector',
          name: 'account-selector',
          component: () => import('@/app/account/AccountSelector.vue')
        },
        {
          path: '/companies/:companyId',
          name: 'company-dashboard',
          component: () => import('@/app/company-dashboard/CompanyDashboard.vue'),
          props: true
        },
        {
          path: '/applications/:applicationId',
          name: 'application-dashboard',
          component: () => import('@/app/company-dashboard/ApplicationDashboard.vue'),
          props: true
        },
        {
          path: '/page2',
          name: 'page-2',
          component: () => import('./views/Page2.vue')
        },


        // =============================================================================
        // Order Manage Routes
        // =============================================================================
        {
          path: '/applications/:applicationId/ordermanager/myorders',
          name: 'ordermanager-instances',
          component: () => import('@/app/ordermanager/OrderManagerInstances.vue'),
          meta: {
            eitherPermissions: 'apps_orders_read,apps_orders_process,apps_orders_edit,apps_orders_cancel',
            pageTitle: 'My Orders',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/ordermanager/:orderId/view',
          name: 'orderdetails-view',
          component: () => import('@/app/ordermanager/OrderDetailsView'),
          meta: {
            eitherPermissions: 'apps_orders_process,apps_orders_edit',
          },
          props: true
        },



        // =============================================================================
        // Company Routes
        // =============================================================================
        {
          path: '/companies/:id/settings',
          name: 'company-settings',
          component: CompanySettings,
          meta: {
            eitherPermissions: 'company_settings_read,company_settings_edit'
          }
        },
        {
          path: '/companies/:companyId/roles',
          name: 'company-roles',
          component: RoleList,
          meta: {
            pageTitle: 'Company Roles',
            eitherPermissions: 'company_roles_read,company_roles_edit'
          }
        },
        {
          path: '/companies/:companyId/roles/add',
          name: 'company-roles-addition',
          component: RoleEditor,
          props: { readOnly: false },
          meta: {
            requiredPermission: 'company_roles_edit'
          }
        },
        {
          path: '/companies/:companyId/roles/:roleId/edit',
          name: 'company-roles-edit',
          component: RoleEditor,
          props: { readOnly: false },
          meta: {
            requiredPermission: 'company_roles_edit'
          }
        },
        {
          path: '/companies/:companyId/roles/:roleId/view',
          name: 'company-roles-view',
          component: RoleEditor,
          props: { readOnly: true },
          meta: {
            eitherPermissions: 'company_roles_read,company_roles_edit'
          }
        },
        {
          path: '/companies/:companyId/users',
          name: 'company-users',
          component: UserList,
          meta: {
            pageTitle: 'Company Users',
            eitherPermissions: 'company_users_read,company_users_edit'
          }
        },
        {
          path: '/companies/:companyId/users/add',
          name: 'company-users-addition',
          component: UserEditor,
          props: { readOnly: false },
          meta: {
            requiredPermission: 'company_users_edit'
          }

        },
        {
          path: '/companies/:companyId/users/:userId/edit',
          name: 'company-users-edit',
          component: UserEditor,
          props: { readOnly: false },
          meta: {
            requiredPermission: 'company_users_edit'
          }
        },
        {
          path: '/companies/:companyId/users/:userId/view',
          name: 'company-users-view',
          component: UserEditor,
          props: { readOnly: true },
          meta: {
            eitherPermissions: 'company_users_read,company_users_edit'
          }
        },
        {
          path: '/companies/:companyId/users/bulkinsert',
          name: 'company-users-bulk-insert',
          component: UserBulkInsert,
          meta: {
            pageTitle: 'Bulk Insert',
            requiredPermission: 'company_users_edit'
          }
        },
        {
          path: '/companies/:companyId/apps',
          name: 'company-applications',
          component: ApplicationList,
          meta: {
            pageTitle: 'Company Applications',
            eitherPermissions: 'company_apps_read,company_apps_create,company_apps_edit'
          }
        },
        {
          path: '/companies/:companyId/apps/create',
          name: 'company-applications-addition',
          component: ApplicationCreate,
          meta: {
            requiredPermission: 'company_apps_create'
          }
        },
        {
          path: '/companies/:companyId/apps/:applicationId',
          name: 'company-applications-editor',
          component: () => import('@/app/company-applications/ApplicationEdit.vue'),
          props: true,
          meta: {
            requiredPermission: 'company_apps_edit'
          }
        },
        {
          path: '/companies/:companyId/dashboards/:dashboardId/edit',
          name: 'company-dashboard-edit',
          component: CompanyDashboardEditor,
          props: true
        },
        {
          path: '/companies/:companyId/widgets/:widgetInstanceId/edit',
          name: 'company-dashboard-widget-edit',
          component: DashboardWidgetEditor,
          props: true
        },
        {
          path: '/companies/:companyId/widgets/add',
          name: 'company-dashboard-widget-add',
          component: DashboardWidgetEditor,
          props: true,
        },

        {
          path: '/applications/:applicationId/dashboards/:dashboardId/edit',
          name: 'application-dashboard-edit',
          component: CompanyDashboardEditor,
          props: true
        },
        {
          path: '/applications/:applicationId/widgets/:widgetInstanceId/edit',
          name: 'application-dashboard-widget-edit',
          component: DashboardWidgetEditor,
          props: true
        },
        {
          path: '/applications/:applicationId/widgets/add',
          name: 'application-dashboard-widget-add',
          component: DashboardWidgetEditor,
          props: true,
        },
        {
          path: '/applications/:applicationId/widgetdirectory',
          name: 'application-widget-directory',
          component: () => import('@/app/application-widgets/ApplicationWidgetDirectory.vue'),
          meta: {
            eitherPermissions: 'apps_widgets_read,apps_widgets_edit,apps_widgets_add'
          },
          props: true
        },
        {
          path: '/applications/:applicationId/widgets',
          name: 'application-widgets',
          component: () => import('@/app/application-widgets/ApplicationWidgets.vue'),
          meta: {
            eitherPermissions: 'apps_widgets_read,apps_widgets_edit,apps_widgets_add'
          },
          props: true
        },
        {
          path: '/applications/:applicationId/widgets/:widgetUrl',
          name: 'application-widget-instances',
          component: () => import('@/app/application-widgets/ApplicationWidgetInstances.vue'),
          meta: {
            eitherPermissions: 'apps_widgets_read,apps_widgets_edit,apps_widgets_add'
          },
          props: true
        },
        {
          path: '/applications/:applicationId/widgets/:widgetUrl/add',
          name: 'application-widget-add',
          component: () => import('@/app/application-widgets/ApplicationWidgetEditor.vue'),
          meta: {
            eitherPermissions: 'apps_widgets_add'
          },
          props: true
        },
        {
          path: '/applications/:applicationId/widgets/:widgetUrl/:widgetInstanceId',
          name: 'application-widget-edit',
          component: () => import('@/app/application-widgets/ApplicationWidgetEditor.vue'),
          meta: {
            eitherPermissions: 'apps_widgets_edit'
          },
          props: true
        },
        {
          path: '/applications/:applicationId/suppliers',
          name: 'supplier-integrations-directory',
          component: () => import('@/app/supplier-integrations/SupplierIntegrationsDirectory.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add'
          },
          props: true
        },
        {
          path: '/applications/:applicationId/suppliers/mysuppliers',
          name: 'supplier-integrations-instances',
          component: () => import('@/app/supplier-integrations/SupplierIntegrationsInstances.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
            pageTitle: 'My Suppliers',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/suppliers/:supplierId',
          name: 'supplier-integrations-details',
          component: () => import('@/app/supplier-integrations/SupplierIntegrationsDetails.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add'
          },
          props: true
        },
        {
          path: '/applications/:applicationId/suppliers/:supplierId/addIntegrations',
          name: 'supplier-integrations-addition',
          component: () => import('@/app/supplier-integrations/SupplierrIntegrationsAddition.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_edit,apps_integrations_add'
          },
          props: true
        },
        {
          path: '/applications/:applicationId/suppliers/:supplierId/integrations/:integrationId',
          name: 'supplier-integrations-edit',
          component: () => import('@/app/supplier-integrations/SupplierIntegrationsEdit.vue'),
          meta: {
            requiredPermission: 'apps_integrations_edit'
          },
          props: true
        },
        {
          path: '/applications/:applicationId/suppliers/:supplierId/integrations/:integrationId/addsettings',
          name: 'supplier-integration-addsettings',
          component: () => import('@/app/supplier-integrations/SupplierrIntegrationsSettingsAddition.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_edit'
          },
          props: true
        },
        {
          path: '/applications/:applicationId/pricingRules',
          name: 'pricing-rules-list',
          component: () => import('@/app/pricing-rule-manager/PricingRuleMangerList'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit'
          },
          props: true
        },
        {
          path: '/applications/:applicationId/pricingRules/add',
          name: 'pricing-rule-addition',
          component: () => import('@/app/pricing-rule-manager/PricingRuleEditor'),
          meta: {
            eitherPermissions: 'apps_integrations_edit'
          },
          props: { readOnly: false }
        },
        {
          path: '/applications/:applicationId/pricingRules/:pricingRuleId/edit',
          name: 'pricing-rule-edit',
          component: () => import('@/app/pricing-rule-manager/PricingRuleEditor'),
          meta: {
            eitherPermissions: 'apps_integrations_edit'
          },
          props: { readOnly: false }
        },
        {
          path: '/applications/:applicationId/pricingRules/:pricingRuleId/view',
          name: 'pricing-rule-view',
          component: () => import('@/app/pricing-rule-manager/PricingRuleEditor'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit'
          },
          props: { readOnly: true }
        },
        // =============================================================================
        // Customer Settings
        // =============================================================================
        {
          path: '/applications/:applicationId/customers',
          name: 'customer-list',
          component: () => import('@/app/customer-settings/CustomerList'),
          meta: {
            eitherPermissions: 'apps_customers_read,apps_customers_edit,apps_customers_add',
            pageTitle: 'My Customers',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/customers/:customerId/view',
          name: 'customer-main-view',
          component: () => import('@/app/customer-settings/CustomerMain'),
          meta: {
            eitherPermissions: 'apps_customers_read,apps_customers_edit,apps_customers_add',
            readOnly: true
          },
          props: true
        },
        {
          path: '/applications/:applicationId/customers/:customerId/edit',
          name: 'customer-main-edit',
          component: () => import('@/app/customer-settings/CustomerMain'),
          meta: {
            eitherPermissions: 'apps_customers_read,apps_customers_edit,apps_customers_add',
            readOnly: false
          },
          props: true
        },
        {
          path: '/applications/:applicationId/customers/:customerId/hotlists/:hotlistId',
          name: 'customer-hotlist-view',
          component: () => import('@/app/customer-settings/CustomerHotlistDetails'),
          meta: {
            eitherPermissions: 'apps_customers_read,apps_customers_edit,apps_customers_add'
          },
          props: true
        },
        {
          path: '/applications/:applicationId/customers/add',
          name: 'customer-addition',
          component: () => import('@/app/customer-settings/CustomerEditorAdd'),
          meta: {
            eitherPermissions: 'apps_customers_edit,apps_customers_add',
          },
          props: { readOnly: false }
        },
        
         // =============================================================================
        // B2B Companies
        // =============================================================================
        {
          path: '/applications/:applicationId/companies',
          name: 'company-list',
          component: () => import('@/app/b2b-companies/CompanyList'),
          meta: {
            eitherPermissions: 'apps_customers_read',
            pageTitle: 'Companies',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/companies/add',
          name: 'company-editor-add',
          component: () => import('@/app/b2b-companies/CompanyEditor'),
          meta: {
            eitherPermissions: 'apps_customers_read,apps_customers_edit,apps_customers_add',
          },
          props: { readOnly: false }
        },
        {
          path: '/applications/:applicationId/companies/:companyId/edit',
          name: 'company-editor-edit',
          component: () => import('@/app/b2b-companies/CompanyEditor'),
          meta: {
            eitherPermissions: 'apps_customers_read,apps_customers_edit,apps_customers_add',
          },
          props: { readOnly: false }
        },
        {
          path: '/applications/:applicationId/companies/:companyId/view',
          name: 'company-editor-view',
          component: () => import('@/app/b2b-companies/CompanyEditor'),
          meta: {
            eitherPermissions: 'apps_customers_read',
          },
          props: { readOnly: true }
        },
        {
          path: '/applications/:applicationId/companies/:companyId/users',
          name: 'company-linked-users',
          component: () => import('@/app/b2b-companies/CompanyLinkedUsers'),
          meta: {
            eitherPermissions: 'apps_customers_read',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/companies/:companyId/credit',
          name: 'company-credit-limit',
          component: () => import('@/app/b2b-companies/CompanyCreditLimits'),
          meta: {
            eitherPermissions: 'apps_customers_read',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/companies/:companyId/credit/add',
          name: 'company-credit-limit-editor-add',
          component: () => import('@/app/b2b-companies/CompanyCreditEditor'),
          meta: {
            eitherPermissions: 'apps_customers_read,apps_customers_edit,apps_customers_add',
          },
          props: { readOnly: false }
        },
        {
          path: '/applications/:applicationId/companies/:companyId/credit/:creditId',
          name: 'company-credit-limit-editor-edit',
          component: () => import('@/app/b2b-companies/CompanyCreditEditor'),
          meta: {
            eitherPermissions: 'apps_customers_read,apps_customers_edit,apps_customers_add',
          },
          props: { readOnly: false }
        },
        {
          path: '/applications/:applicationId/companies/:companyId/credit/:creditId',
          name: 'company-credit-limit-editor-view',
          component: () => import('@/app/b2b-companies/CompanyCreditEditor'),
          meta: {
            eitherPermissions: 'apps_customers_read,apps_customers_edit,apps_customers_add',
          },
        },
        {
          path: '/applications/:applicationId/companies/:companyId/credit/:creditId/usage',
          name: 'company-credit-usage-view',
          component: () => import('@/app/b2b-companies/CompanyCreditUsages'),
          meta: {
            eitherPermissions: 'apps_customers_read',
          },
        },
        //Enquiry Viewer
        {
          path: '/applications/:applicationId/enquiryViewer',
          name: 'enquiry-viewer-list',
          component: () => import('@/app/enquiry-viewer/EnquiryViewerList'),
          // meta: {
          //   eitherPermissions: 'apps_enquiryviewer_read,apps_enquiryviewer_edit'
          // },
          props: true
        },
        //Translations
        {
          path: '/applications/:applicationId/translations',
          name: 'application-translations',
          component: () => import('@/app/translations/TranslationList.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
            pageTitle: 'App Translations',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/translations/:translationId',
          name: 'application-translation-edit',
          component: () => import('@/app/translations/TranslationEdit.vue'),
          meta: {
            // eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/enquiryViewer/:webReference/view',
          name: 'enquiry-viewer-view',
          component: () => import('@/app/enquiry-viewer/EnquiryViewerDetail'),
          // meta: {
          //   eitherPermissions: 'apps_enquiryviewer_read,apps_enquiryviewer_edit'
          // },
          props: { readOnly: true }
        },
        //Currency Exchange
        {
          path: '/applications/:applicationId/currencyExchange',
          name: 'application-currency-exchange',
          component: () => import('@/app/currency-exchange/CurrencyExchangeHomePage.vue'),
          meta: {
            eitherPermissions: 'app_currex_read,app_currex_edit',
            pageTitle: 'Currency Exchange',
          },
          props: true
        },
        // Currency Exchange - Branch Manager
        {
          path: '/applications/:applicationId/currencyExchange/branches',
          name: 'application-currency-exchange-branches',
          component: () => import('@/app/currency-exchange/branch-manager/BranchManagerList.vue'),
          meta: {
            eitherPermissions: 'app_currex_read,app_currex_edit',
          },
          props: true,
        },
        {
          path: '/applications/:applicationId/currencyExchange/branches/add',
          name: 'application-currency-exchange-branches-add',
          component: () => import('@/app/currency-exchange/branch-manager/BranchManagerEditor.vue'),
          meta: {
            eitherPermissions: 'app_currex_read,app_currex_edit',
          },
          props: true,
        },
        {
          path: '/applications/:applicationId/currencyExchange/branches/:branchId/edit',
          name: 'application-currency-exchange-branches-edit',
          component: () => import('@/app/currency-exchange/branch-manager/BranchManagerEditor.vue'),
          meta: {
            eitherPermissions: 'app_currex_read,app_currex_edit',
          },
          props: true,
        },
        // Currency Exchange - Delivery Fee
        {
          path: '/applications/:applicationId/currencyExchange/deliveryfees',
          name: 'application-currency-exchange-delivery-fees',
          component: () => import('@/app/currency-exchange/delivery-fee-manager/DeliveryFeeList.vue'),
          meta: {
            eitherPermissions: 'app_currex_read,app_currex_edit',
          },
          props: true,
        },
        {
          path: '/applications/:applicationId/currencyExchange/deliveryfees/add',
          name: 'application-currency-exchange-delivery-fees-add',
          component: () => import('@/app/currency-exchange/delivery-fee-manager/DeliveryFeeEditor.vue'),
          meta: {
            eitherPermissions: 'app_currex_read,app_currex_edit',
          },
          props: true,
        },
        {
          path: '/applications/:applicationId/currencyExchange/deliveryfees/:deliveryFeeId',
          name: 'application-currency-exchange-delivery-fees-edit',
          component: () => import('@/app/currency-exchange/delivery-fee-manager/DeliveryFeeEditor.vue'),
          meta: {
            eitherPermissions: 'app_currex_read,app_currex_edit',
          },
          props: true,
        },
        // Currency Exchange - Currency Manager
        {
          path: '/applications/:applicationId/currencyExchange/currencyManager',
          name: 'application-currency-exchange-currencies',
          component: () => import('@/app/currency-exchange/currency-manager/CurrencyManagerList.vue'),
          meta: {
            eitherPermissions: 'app_currex_read,app_currex_edit',
          },
          props: true,
        },
        {
          path: '/applications/:applicationId/currencyExchange/currencies/add',
          name: 'application-currency-exchange-currencies-add',
          component: () => import('@/app/currency-exchange/currency-manager/CurrencyManagerEditor.vue'),
          meta: {
            eitherPermissions: 'app_currex_read,app_currex_edit',
          },
          props: true,
        },
        {
          path: '/applications/:applicationId/currencyExchange/currencies/:currencyId',
          name: 'application-currency-exchange-currencies-edit',
          component: () => import('@/app/currency-exchange/currency-manager/CurrencyManagerEditor.vue'),
          meta: {
            eitherPermissions: 'app_currex_read,app_currex_edit',
          },
          props: true,
        },
        // Currency Exchange - Exchange Rate Manager
        {
          path: '/applications/:applicationId/currencyExchange/exchangeRateManager',
          name: 'application-currency-exchange-rate-manager',
          component: () => import('@/app/currency-exchange/exchange-rate-manager/ExchangeRateList.vue'),
          meta: {
            eitherPermissions: 'app_currex_read,app_currex_edit',
          },
          props: true,
        },
        //Deposit Options
        {
          path: '/applications/:applicationId/depositoptions',
          name: 'application-depositoptions',
          component: () => import('@/app/deposit-options/DepositOptionsList.vue'),
          meta: {
            eitherPermissions: 'apps_rules_read,apps_rules_edit',
            pageTitle: 'Deposit Options',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/depositoptions/add',
          name: 'application-depositoptions-add',
          component: () => import('@/app/deposit-options/DepositOptionsAdd.vue'),
          meta: {
            eitherPermissions: 'apps_rules_edit',
            // pageTitle: 'Edit Deposit Options',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/depositoptions/:depositOptionId/edit',
          name: 'application-depositoptions-edit',
          component: () => import('@/app/deposit-options/DepositOptionsEdit.vue'),
          meta: {
            eitherPermissions: 'apps_rules_edit',
            // pageTitle: 'Edit Deposit Options',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/depositoptions/:depositOptionId/view',
          name: 'application-depositoptions-view',
          component: () => import('@/app/deposit-options/DepositOptionsView.vue'),
          meta: {
            eitherPermissions: 'apps_rules_read,apps_rules_edit',
            // pageTitle: 'Edit Deposit Options',
          },
          props: true
        },
          //Deposit Rules
          {
            path: '/applications/:applicationId/depositrules',
            name: 'application-depositrules',
            component: () => import('@/app/deposit-rules/DepositRulesList.vue'),
            meta: {
              eitherPermissions: 'apps_rules_read,apps_rules_edit',
              pageTitle: 'Deposit Rules',
            },
            props: true,
          },
          {
            path: '/applications/:applicationId/depositrules/add',
            name: 'application-depositrules-add',
            component: () => import('@/app/deposit-rules/DepositRulesEditor.vue'),
            meta: {
              eitherPermissions: 'apps_rules_edit',
              // pageTitle: 'Edit Deposit Options',
            },
            props: { readOnly: false }
          },
          {
            path: '/applications/:applicationId/depositrules/:depositRuleId/edit',
            name: 'application-depositrules-edit',
            component: () => import('@/app/deposit-rules/DepositRulesEditor.vue'),
            meta: {
              eitherPermissions: 'apps_rules_edit',
              // pageTitle: 'Edit Deposit Options',
            },
            props: { readOnly: false }
          },
          {
            path: '/applications/:applicationId/depositrules/:depositRuleId/view',
            name: 'application-depositrules-view',
            component: () => import('@/app/deposit-rules/DepositRulesEditor.vue'),
            meta: {
              eitherPermissions: 'apps_rules_read,apps_rules_edit',
              // pageTitle: 'Edit Deposit Options',
            },
            props: true
          },
          //Voucher Manager
          {
            path: '/applications/:applicationId/voucherRules',
            name: 'application-voucherrules',
            component: () => import('@/app/voucher-manager/VoucherRulesList.vue'),
            meta: {
              eitherPermissions: 'apps_rules_read,apps_rules_edit',
              pageTitle: 'Promo Code Rules',
            },
            props: true,
          },
          {
            path: '/applications/:applicationId/voucherRules/add',
            name: 'application-voucherrules-add',
            component: () => import('@/app/voucher-manager/VoucherRulesEditor.vue'),
            meta: {
              eitherPermissions: 'apps_rules_edit',
              // pageTitle: 'Edit Deposit Options',
            },
            props: { readOnly: false }
          },
          {
            path: '/applications/:applicationId/voucherRules/:voucherRuleId/edit',
            name: 'application-voucherrules-edit',
            component: () => import('@/app/voucher-manager/VoucherRulesEditor.vue'),
            meta: {
              eitherPermissions: 'apps_rules_edit',
              // pageTitle: 'Edit Deposit Options',
            },
            props: { readOnly: false }
          },
          {
            path: '/applications/:applicationId/voucherRules/:voucherRuleId/view',
            name: 'application-voucherrules-view',
            component: () => import('@/app/voucher-manager/VoucherRulesEditor.vue'),
            meta: {
              eitherPermissions: 'apps_rules_edit',
              // pageTitle: 'Edit Deposit Options',
            },
            props: { readOnly: true }
          },
          {
            path: '/applications/:applicationId/voucherRules/:voucherRuleId/voucherCodes',
            name: 'application-vouchercodes',
            component: () => import('@/app/voucher-manager/VoucherCodesList.vue'),
            meta: {
              eitherPermissions: 'apps_rules_edit',
              // pageTitle: 'Edit Deposit Options',
            },
            props: true
          },
          {
            path: '/applications/:applicationId/voucherRules/:voucherRuleId/voucherCodes/add',
            name: 'application-vouchercodes-add',
            component: () => import('@/app/voucher-manager/VoucherCodeEditor.vue'),
            meta: {
              eitherPermissions: 'apps_rules_edit',
              // pageTitle: 'Edit Deposit Options',
            },
            props: true
          },
          {
            path: '/applications/:applicationId/voucherRules/:voucherRuleId/voucherCodes/:voucherCodeId/edit',
            name: 'application-vouchercodes-edit',
            component: () => import('@/app/voucher-manager/VoucherCodeEditor.vue'),
            meta: {
              eitherPermissions: 'apps_rules_edit',
              // pageTitle: 'Edit Deposit Options',
            },
            props: true
          },
          {
            path: '/applications/:applicationId/voucherRules/:voucherRuleId/voucherCodes/:voucherCodeId/view',
            name: 'application-vouchercodes-view',
            component: () => import('@/app/voucher-manager/VoucherCodeDetails.vue'),
            meta: {
              eitherPermissions: 'apps_rules_read,apps_rules_edit',
              // pageTitle: 'Edit Deposit Options',
            },
            props: true
          },
        //Tools
        {
          path: '/applications/:applicationId/tools',
          name: 'application-tools',
          component: () => import('@/app/tools/ToolList.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
            pageTitle: 'Tools',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/rsslinkbuilder',
          name: 'application-tools-rss-link-builder',
          component: () => import('@/app/tools/rss-link-builder/RssLinkBuilder.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
            
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/accommodation-overrides',
          name: 'application-tools-accommodation-overrides',
          component: () => import('@/app/tools/accommodation-overrides/AccomodationOverrideList.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/location-lists',
          name: 'application-tools-location-lists',
          component: () => import('@/app/tools/location-lists/LocationLists.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/top-locations',
          name: 'application-tools-top-locations',
          component: () => import('@/app/tools/top-locations/TopLocationMain.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/location-lists/:locationListId/level1',
          name: 'application-tools-location-editor-level1',
          component: () => import('@/app/tools/location-lists/LocationListsEditorLevel1.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/location-lists/:locationListId/:locationId/level2',
          name: 'application-tools-location-editor-level2',
          component: () => import('@/app/tools/location-lists/LocationListsEditorLevel2.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/location-lists/:locationListId/:locationId/level3',
          name: 'application-tools-location-editor-level3',
          component: () => import('@/app/tools/location-lists/LocationListsEditorLevel3.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/propertyContent',
          name: 'application-tools-property-content',
          component: () => import('@/app/tools/property-content/PropertyContentList.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/propertyContent/:propertyId/edit',
          name: 'application-tools-property-content-edit',
          component: () => import('@/app/tools/property-content/PropertyContentEditor.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/propertyContent/new',
          name: 'application-tools-property-content-add',
          component: () => import('@/app/tools/property-content/PropertyContentEditor.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/extraGroups',
          name: 'application-tools-extra-groups',
          component: () => import('@/app/tools/custom-extras/ExtraGroupList.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/extraGroups/new',
          name: 'application-tools-extra-groups-add',
          component: () => import('@/app/tools/custom-extras/ExtraGroupEditor.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/extraGroups/:groupId',
          name: 'application-tools-extra-groups-edit',
          component: () => import('@/app/tools/custom-extras/ExtraGroupEditor.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/extraGroups/:groupId/extras',
          name: 'application-tools-extra-groups-extra-list',
          component: () => import('@/app/tools/custom-extras/ExtraList.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/extraGroups/:groupId/extras/new',
          name: 'application-tools-extra-groups-extra-add',
          component: () => import('@/app/tools/custom-extras/ExtraEditor.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/extraGroups/:groupId/extras/edit/:extraId',
          name: 'application-tools-extra-groups-extra-edit',
          component: () => import('@/app/tools/custom-extras/ExtraEditor.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/extraGroups/:groupId/extras/edit/:extraId',
          name: 'application-tools-extra-groups-extra-view',
          component: () => import('@/app/tools/custom-extras/ExtraEditor.vue'),
          meta: {
            readOnly: true,
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        // Rewards Rule
        {
          path: '/applications/:applicationId/tools/rewardRules',
          name: 'application-tools-reward-rules-list',
          component: () => import('@/app/tools/rewards-rule/RewardRulesList.vue'),
          meta: {
            // eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/rewardRules/new',
          name: 'application-tools-reward-rules-create',
          component: () => import('@/app/tools/rewards-rule/RewardRuleEditor.vue'),
          meta: {
            // eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/rewardRules/:ruleId',
          name: 'application-tools-reward-rules-edit',
          component: () => import('@/app/tools/rewards-rule/RewardRuleEditor.vue'),
          meta: {
            // eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        
        {
          path: '/applications/:applicationId/tools/googleSheets',
          name: 'application-tools-google-sheets',
          component: () => import('@/app/tools/google-sheets/GoogleSheetsList.vue'),
          meta: {
            // eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/skyscannerLocations',
          name: 'application-tools-skyscanner-locations',
          component: () => import('@/app/tools/skyscanner-locations/SkyscannerLocationList.vue'),
          meta: {
            // eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/skyscannerLocations/new',
          name: 'application-tools-skyscanner-locations-add',
          component: () => import('@/app/tools/skyscanner-locations/SkyscannerLocationEditor.vue'),
          meta: {
            // eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/skyscannerLocations/:locationId/edit',
          name: 'application-tools-skyscanner-locations-edit',
          component: () => import('@/app/tools/skyscanner-locations/SkyscannerLocationEditor.vue'),
          meta: {
            //eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/giftVouchers',
          name: 'application-tools-gift-vouchers',
          component: () => import('@/app/tools/gift-vouchers/GiftVoucherList.vue'),
          meta: {
            // eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/giftVouchers/:giftVoucherId/edit',
          name: 'application-tools-gift-vouchers-edit',
          component: () => import('@/app/tools/gift-vouchers/GiftVoucherEditor.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/giftVouchers/new',
          name: 'application-tools-gift-vouchers-add',
          component: () => import('@/app/tools/gift-vouchers/GiftVoucherEditor.vue'),
          meta: {
            eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },

        {
          path: '/applications/:applicationId/tools/rewardRules/:ruleId/view',
          name: 'application-tools-reward-rules-view',
          component: () => import('@/app/tools/rewards-rule/RewardRuleEditor.vue'),
          meta: {
            readOnly: true,
            // eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/rewards',
          name: 'application-tools-reward-list',
          component: () => import('@/app/tools/rewards-rule/RewardsList.vue'),
          meta: {
            // eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/rewards/new',
          name: 'application-tools-reward-add',
          component: () => import('@/app/tools/rewards-rule/RewardEditor.vue'),
          meta: {
            // eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/rewards/:rewardId',
          name: 'application-tools-reward-edit',
          component: () => import('@/app/tools/rewards-rule/RewardEditor.vue'),
          meta: {
            // eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/rewards/:rewardId/view',
          name: 'application-tools-reward-view',
          component: () => import('@/app/tools/rewards-rule/RewardEditor.vue'),
          meta: {
            readOnly: true,
            // eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/membership-levels',
          name: 'application-tools-membership-levels',
          component: () => import('@/app/tools/membership-levels/MembershipLevelsList.vue'),
          meta: {
            // eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/membership-levels/new',
          name: 'application-tools-membership-levels-add',
          component: () => import('@/app/tools/membership-levels/MembershipLevelEditor.vue'),
          meta: {
            readOnly: false
            // eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        {
          path: '/applications/:applicationId/tools/membership-levels/:membershipId',
          name: 'application-tools-membership-levels-edit',
          component: () => import('@/app/tools/membership-levels/MembershipLevelEditor.vue'),
          meta: {
            readOnly: false
            // eitherPermissions: 'apps_integrations_read,apps_integrations_edit,apps_integrations_add',
          },
          props: true
        },
        // Enquiry Viewer Sections Ends
        // Quote Viewer
        {
          path: '/applications/:applicationId/quoteViewer',
          name: 'application-tools-quote-viewer-list',
          component: () => import('@/app/quote-viewer/QuoteViewerList'),
          // meta: {
          //   eitherPermissions: 'apps_enquiryviewer_read,apps_enquiryviewer_edit'
          // },
          props: true
        },
        {
          path: '/applications/:applicationId/quoteViewer/:listId',
          name: 'application-tools-quote-viewer-details',
          component: () => import('@/app/quote-viewer/QuoteViewerDetails'),
          // meta: {
          //   eitherPermissions: 'apps_enquiryviewer_read,apps_enquiryviewer_edit'
          // },
          props: true
        },
        {
          path: '/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/NotAuthorized.vue')
        },
        {
          path: '/error-404',
          name: 'page-not-found',
          component: () => import('@/views/Error404.vue')
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      meta: {
        requiresVisitor: true
      },
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'page-login',
          component: () => import('@/app/authentication/Login.vue')
        },
        {
          path: '/login/twofactor',
          name: 'page-login-twofactor',
          component: () => import('@/app/authentication/TwoFactorLogin.vue')
        },
        {
          path: '/login/twofactorSetup',
          name: 'page-login-twofactor-setup',
          component: () => import('@/app/authentication/TwoFactorSetup.vue')
        },
        {
          path: '/login/twofactorbackup',
          name: 'page-login-twofactorbackup',
          component: () => import('@/app/authentication/TwoFactorBackupLogin.vue')
        },
        {
          path: '/loginTwitterRedirect',
          name: 'twitter-login-redirect',
          component: () => import('@/app/authentication/LoginTwitterRedirect.vue')
        },
        {
          path: '/register',
          name: 'page-register',
          component: () => import('@/app/authentication/Register.vue')
        },
        {
          path: '/forgottenpassword',
          name: 'page-forgotten-password',
          component: () => import('@/app/authentication/ForgottenPassword.vue')
        },
        {
          path: '/resetpassword',
          name: 'page-reset-password',
          component: () => import('@/app/authentication/ResetPassword.vue')
        },
        {
          path: '/emailverification/:token',
          name: 'page-email-verification',
          component: () => import('@/app/authentication/EmailVerification.vue'),
          meta: {
            requresAuth: true
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/Error404.vue')
        },

        //Playground to test
        {
          path: '/playground/card',
          name: 'playground-card',
          component: () => import('@/views/playground/CardPlayground.vue')
        },
      ]
    },
    // =============================================================================
    // HOST ADMIN LAYOUTs
    // =============================================================================
    {
      path: '',
      component: () => import('./layouts/host-admin/HostAdmin.vue'),
      meta: {
        //TODO: require auth using a host admin permission
        requresAuth: false
      },
      children: [
        {
          path: '/admin/crm',
          name: 'admin-crm',
          component: () => import('@/app/host-admin-all-companies/AllCompanies.vue'),
          meta: {

          }
        },
        {
          path: '/admin/crm/companies/add',
          name: 'admin-crm-company-add',
          component: () => import('@/app/host-admin-crm/CompanyAddition.vue'),
          meta: {

          }
        },
        {
          path: '/admin/crm/companies/:companyId/edit',
          name: 'admin-crm-company-edit',
          component: () => import('@/app/host-admin-crm/CompanyEdit.vue'),
          props: true,
          meta: {

          }
        },
        {
          path: '/admin/crm/companies/:companyId/view',
          name: 'admin-crm-company-view',
          component: () => import('@/app/host-admin-crm/CompanyView.vue'),
          props: true,
          meta: {

          }
        },
        {
          path: '/admin/crm/companies/:companyId/users/add',
          name: 'admin-crm-user-add',
          component: () => import('@/app/host-admin-crm/CompanyUserEditor.vue'),
          props: true,
          meta: {

          }
        },
        {
          path: '/admin/crm/companies/:companyId/users/:userId',
          name: 'admin-crm-user-edit',
          component: () => import('@/app/host-admin-crm/CompanyUserEditor.vue'),
          props: true,
          meta: {

          }
        },
        {
          path: '/admin/crm/companies/:companyId/users/:userId/view',
          name: 'admin-crm-user-view',
          component: () => import('@/app/host-admin-crm/CompanyUserDetails.vue'),
          props: true,
          meta: {

          }
        },
        {
          path: '/admin/crm/companies/:companyId/apps/add',
          name: 'admin-crm-apps-add',
          component: () => import('@/app/host-admin-crm/CompanyAppCreate.vue'),
          props: true,
          meta: {

          }
        },
        {
          path: '/admin/crm/companies/:companyId/apps/:applicationId',
          name: 'admin-crm-apps-edit',
          component: () => import('@/app/host-admin-crm/CompanyAppEdit.vue'),
          props: true,
          meta: {

          }
        },
        {
          path: '/admin/crm/companies/:companyId/apps/:applicationId/view',
          name: 'admin-crm-apps-view',
          component: () => import('@/app/host-admin-crm/CompanyAppDetails.vue'),
          props: true,
          meta: {

          }
        },
        {
          path: '/admin/suppliers',
          name: 'admin-supplier',
          component: () => import('@/app/host-admin-suppliers/SupplierList.vue'),
          meta: {

          }
        },
        {
          path: '/admin/suppliers/add',
          name: 'admin-supplier-add',
          component: () => import('@/app/host-admin-suppliers/SupplierAdd.vue'),
          meta: {

          }
        },
        {
          path: '/admin/suppliers/:supplierId/edit',
          name: 'admin-supplier-edit',
          component: () => import('@/app/host-admin-suppliers/SupplierEdit.vue'),
          props: true,
          meta: {

          }
        },
        {
          path: '/admin/suppliers/:supplierId/details',
          name: 'admin-supplier-view',
          component: () => import('@/app/host-admin-suppliers/SupplierDetails.vue'),
          props: true,
          meta: {

          }
        },
        {
          path: '/admin/suppliers/:supplierId/settings/add',
          name: 'admin-supplier-settings-add',
          component: () => import('@/app/host-admin-suppliers/SupplierSettingsAdd.vue'),
          props: true,
          meta: {

          }
        },
        {
          path: '/admin/suppliers/:supplierId/settings/:settingName',
          name: 'admin-supplier-settings-edit',
          component: () => import('@/app/host-admin-suppliers/SupplierSettingsEdit.vue'),
          props: true,
          meta: {

          }
        },
        {
          path: '/admin/suppliers/:supplierId/settings/:settingName/view',
          name: 'admin-supplier-settings-view',
          component: () => import('@/app/host-admin-suppliers/SupplierSettingsDetails.vue'),
          props: true,
          meta: {

          }
        },
        {
          path: '/admin/suppliers/:supplierId/healthcheck',
          name: 'admin-supplier-settings-healthcheck',
          component: () => import('@/app/host-admin-suppliers/SupplierHealth.vue'),
          props: true,
          meta: {

          }
        },
        {
          path: '/admin/locationNormalisation',
          name: 'localisation-normalisation',
          component: () => import('@/app/host-admin-location-normalisation/LocationNormalisationList.vue'),
          meta: {

          }
        },
        {
          path: '/admin/locationNormalisation/:countryCode/:normalisedName',
          name: 'localisation-normalisation-edit',
          component: () => import('@/app/host-admin-location-normalisation/LocationNormalisationEditor.vue'),
          props: true,
          meta: {

          }
        },
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requresAuth)) {
    if (!store.getters['auth/isLoggedIn']) {
      const redirectQuery = to.path != '/' ? { redirect: to.path } : null
      next({name: 'page-login', query: redirectQuery})
    } else if ((to.meta.requiredPermission || to.meta.eitherPermissions) && !hasPermission(to.meta)) {
      next({name: 'page-not-authorized'})
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters['auth/isLoggedIn']) {
      next({name: 'home'})
    } else {
      next();
    }
  } else {
    next();
  }
})

function hasPermission2(requiredPermission) {
  const userPermissions = store.getters['auth/getUserPermissions']
  return _.includes(userPermissions, requiredPermission)
}

function hasPermission(routeMeta) {
  let result = true
  if (routeMeta.eitherPermissions) {
    result = router.app.$hasPermission(routeMeta.eitherPermissions, store.getters['auth/getUserPermissions'])
  } else if (routeMeta.requiredPermission) {
    result = _.includes(store.getters['auth/getUserPermissions'], routeMeta.requiredPermission)
  }
  return result;
}

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
})

export default router
