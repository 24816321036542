<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon title="View Details" icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="displayRecord" />
      <feather-icon v-if="userHasPermission('company_roles_edit') && !isSystemRole" title="Edit" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon v-if="userHasPermission('company_roles_edit') && !isSystemRole" title="Delete" icon="TrashIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
     <vs-prompt
        title="Delete Role"
        accept-text="Delete"
        @cancel="closeDeleteDialog"
        @close="closeDeleteDialog"
        @accept="deleteRole"
        :active.sync="showDeleteDialog">

          <div class="vx-row mt-2 mb-4">
            <div class="vx-col">
              <span v-if="deleteAffectedUsersCount > 0">You are about to delete a role assigned to {{deleteAffectedUsersCount}} user(s). Select a replacement role for these user(s).</span>
              <span v-if="deleteAffectedUsersCount == 0">Are you sure you want to delete '{{this.params.data.roleName}}' role?</span>
            </div>
            <div v-if="deleteAffectedUsersCount > 0" class="vx-col w-full mt-4">
              <label class="text-sm">Select Replacement Role:</label>
              <v-select v-model="selectedReplacementRole" :options="replacementRoleOptions" />
            </div>
          </div>
      </vs-prompt>
    </div>
</template>

<script>
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'
import vSelect from 'vue-select'
import _ from 'lodash'
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';

export default {
  name: 'CellRendererActions',
  components: {
    vSelect
  },
  mixins: [TpNotificationMixin],
  data () {
    return {
      showDeleteDialog: false,
      deleteAffectedUsersCount: 0,
      deleteHaveAffectedUsers: false,
      selectedReplacementRole: null
    }
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    isSystemRole () {
      return this.params.data.roleType.toLowerCase() === 'system'
    },
    replacementRoleOptions () {
      let filteredRoles = _.filter(this.$store.state.roleStore.companyRoles, x => x.roleId != this.params.data.roleId)
      return _.map(filteredRoles, x => {
        return { code: x.roleId, label: x.roleName }
      })
    }
  },
  methods: {
    editRecord () {
      if (this.params.data.roleType.toLowerCase() === 'system') {
        this.$_notifyFailure('System roles cannot be updated.')
      } else {
        this.$router.push(`/companies/${this.activeUserInfo.companyId}/roles/${this.params.data.roleId}/edit`)
          .catch((error) => { console.error(error) })
      }
    },
    displayRecord () {
      this.$router.push({ name: 'company-roles-view', params: { 
        companyId: this.activeUserInfo.companyId,
        roleId: this.params.data.roleId
      }, query: {isSystem: this.isSystemRole} })
      .catch((error) => { console.error(error) })
    },
    confirmDeleteRecord () {
      if (this.params.data.roleType.toLowerCase() === 'system') {
        this.$_notifyFailure('System roles cannot be deleted.')
        return;
      }

      this.$vs.loading()
      this.$http.get(`api/companies/${this.activeUserInfo.companyId}/roles/${this.params.data.roleId}/userCount`)
        .then(response => {
          this.showDeleteDialog = true
          this.deleteHaveAffectedUsers = response.data > 0
          this.deleteAffectedUsersCount = response.data
        })
        .catch(error => {
          this.$_notifyFailureByResponseData(error.response.data)
        })
        .finally(() => this.$vs.loading.close())
    },
    closeDeleteDialog () {
      this.deleteHaveAffectedUsers = false
      this.deleteAffectedUsersCount = 0
      this.selectedReplacementRole = null
    },
    deleteRole () {
      if (this.deleteAffectedUsersCount > 0 && !this.selectedReplacementRole ) {
        this.$_notifyFailure('Unable to delete role. Please select replacement role for affected users.')
        return
      }
      this.$vs.loading()
      this.$store.dispatch('roleStore/removeRole', { 
        companyId: this.activeUserInfo.companyId,
        roleId: this.params.data.roleId,
        replacementRoleId: this.selectedReplacementRole && this.selectedReplacementRole.code
      })
      .then(response => {
        this.$_notifySuccess('Successfully deleted role')
      })
      .catch(error => {
        this.$_notifyFailureByResponseData(error.response.data)
      })
      .finally(() => this.$vs.loading.close())
    }
  }
}
</script>
