import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"
import auth from '@/app/authentication/authenticationStore.js'

import accountStore from '@/app/account/accountStore.js'
import applicationDashboardStore from '@/app/company-dashboard/applicationDashboardStore.js'
import applicationWidgetStore from '@/app/application-widgets/applicationWidgetStore.js'
Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    // Other store modules will be added dynamically when the component loads
    auth,
    accountStore,
    applicationDashboardStore,
    applicationWidgetStore
  },
  strict: process.env.NODE_ENV !== 'production'
})
