<template>
  <!-- Fragment necessary since we don't want extra elements at the root of this component -->
  <!-- https://github.com/vuejs/vue/issues/5758 -->
  <fragment v-if="hasPermission && useFragment">
    <slot />
  </fragment>
  <div v-else-if="hasPermission">
    <slot />
  </div>
</template>

<script>
import { Fragment } from 'vue-fragment'

export default {
  name: 'travio-access-control',
  components: {
    Fragment
  },
  props: {
    requiredPermission: { type: String, default: null },
    eitherPermissions: { type: String, default: null },
    useFragment: { type: Boolean, default: true }
  },
  computed: {
    hasPermission () {
      let result = true
      
      if (this.eitherPermissions) {
        this.$hasPermission(this.eitherPermissions, this.$store.getters['auth/getUserPermissions'])
      } else if (this.requiredPermission) {
          result = this.$_.includes(this.$store.getters['auth/getUserPermissions'], this.requiredPermission)
      }
      return result;
    }
  }
}
</script>