import axios from '@/axios'

export class Form {
  constructor(data) {
    this.originalData = data;
    for (let field in data) {
      this[field] = data[field];
    }
  }

  data() {
    let data = {};

    for (let property in this.originalData) {
      data[property] = this[property];
    }
    return data;
  }

  isDirty () {
    const currentData = JSON.stringify(this.data());
    const originalData = JSON.stringify(this.originalData);
    return currentData !== originalData
  }
  
  reset() {
    for (let field in this.originalData) {
      this[field] = '';
    }
  }

  post(url) {
    return this.submit('post', url);
  }

  put(url) {
    return this.submit('put', url);
  }

  delete(url) {
    return this.submit('delete', url);
  }

  submit(requestType, url) {
    return new Promise((resolve, reject) => {
      axios[requestType](url, this.data())
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          this.onFail(error.response.data.data);
          reject(error);
        });
    });
  }

  onSuccess(data) {
    //
  }

  onFail(errorResponse) {
    //
  }
}