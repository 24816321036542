var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { gridWidth: "1/2", pageTitle: _vm.pageTitle } },
    [
      _c(
        "vx-card",
        { attrs: { title: _vm.widgetName } },
        [
          _c("div", { staticClass: "vx-row mt-4 mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Widget Title*")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:100",
                      expression: "'required|max:100'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { name: "Widget Title", disabled: _vm.readOnly },
                  model: {
                    value: _vm.form.widgetTitle,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "widgetTitle", $$v)
                    },
                    expression: "form.widgetTitle",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Widget Title"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
              _c("span", [_vm._v("Width*")]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col sm:w-4/5 w-full" },
              [
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: { options: _vm.widthOptions, name: "Width" },
                  on: { input: _vm.setSelectedCssWidth },
                  model: {
                    value: _vm.selectedCssWidth,
                    callback: function ($$v) {
                      _vm.selectedCssWidth = $$v
                    },
                    expression: "selectedCssWidth",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Width"))),
                ]),
              ],
              1
            ),
          ]),
          !_vm.hideDateRange
            ? _c("div", { staticClass: "vx-row mb-4" }, [
                _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                  _c("span", [_vm._v("Custom Date")]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col sm:w-4/5 w-full" },
                  [
                    _c("vs-checkbox", {
                      on: {
                        change: function ($event) {
                          return _vm.resetDateRange()
                        },
                      },
                      model: {
                        value: _vm.useCustomDate,
                        callback: function ($$v) {
                          _vm.useCustomDate = $$v
                        },
                        expression: "useCustomDate",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          !_vm.useCustomDate && !_vm.hideDateRange && !_vm.hidePresetRange
            ? _c("div", { staticClass: "vx-row mb-4" }, [
                _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                  _c("span", [_vm._v("Date Range*")]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col sm:w-4/5 w-full" },
                  [
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        options: _vm.dateRangeOptions,
                        name: "Date Range",
                      },
                      on: { input: _vm.setSelectedDateRange },
                      model: {
                        value: _vm.selectedDateRange,
                        callback: function ($$v) {
                          _vm.selectedDateRange = $$v
                        },
                        expression: "selectedDateRange",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("Date Range"))),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.useCustomDate && !_vm.hideDateRange
            ? _c("div", { staticClass: "vx-row mb-4" }, [
                _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                  _c("span", [_vm._v("Date Range*")]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col sm:w-4/5 w-full" },
                  [
                    _c("flat-pickr", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "vx-col w-full",
                      attrs: {
                        name: "Custom Date Range",
                        config: {
                          mode: "range",
                          maxDate: "today",
                        },
                      },
                      model: {
                        value: _vm.form.customDateRange,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "customDateRange", $$v)
                        },
                        expression: "form.customDateRange",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("Custom Date Range"))),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.componentEditor
            ? _c(
                _vm.componentEditor,
                _vm._b(
                  {
                    ref: "childEditorComponent",
                    tag: "component",
                    on: { changed: _vm.handleChildChanged },
                  },
                  "component",
                  {
                    formData: _vm.additionalSettingsInitValue,
                    useCustomDate: _vm.useCustomDate,
                  },
                  false
                )
              )
            : _vm._e(),
          _vm.isCompanyDashboard && _vm.showAppSelection
            ? _c(
                "vx-card",
                {
                  staticClass: "mt-base",
                  attrs: { "no-shadow": "", "card-border": "" },
                },
                [
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("div", { staticClass: "flex items-end px-3" }, [
                          _c(
                            "span",
                            { staticClass: "font-medium text-lg leading-none" },
                            [_vm._v("Applications")]
                          ),
                        ]),
                        _c("vs-divider", { staticClass: "mb-0" }),
                      ],
                      1
                    ),
                  ]),
                  _vm._l(
                    _vm.applications,
                    function (application, applicationIndex) {
                      return _c(
                        "div",
                        { key: applicationIndex, staticClass: "mt-4" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              attrs: {
                                "vs-value": application.id,
                                disabled: _vm.readOnly,
                              },
                              model: {
                                value: _vm.form.selectedApplicationIds,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "selectedApplicationIds",
                                    $$v
                                  )
                                },
                                expression: "form.selectedApplicationIds",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(application.name) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
          _c("div", { staticClass: "lg:float-left mt-4" }, [
            _c("span", { staticClass: "text-sm text-danger" }, [
              _vm._v("*Required Field"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-end" },
            [
              !_vm.readOnly
                ? _c(
                    "vs-button",
                    {
                      staticClass: "mt-4 mr-4",
                      attrs: { color: "danger" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("Cancel")]
                  )
                : _vm._e(),
              !_vm.readOnly
                ? _c(
                    "vs-button",
                    { staticClass: "mt-4", on: { click: _vm.handleSubmit } },
                    [_vm._v("Save")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }