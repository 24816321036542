<template>
  <div>
    <div class="vx-row mb-4">
      <div class="vx-col sm:w-1/5 w-full">
        <span>Search Type</span>
      </div>
      <div class="vx-col sm:w-4/5 w-full">
        <v-select v-model="selectedSearchType" @input="setSelectedSearchType" :options="[{ label: 'Accomodation', code: 1 },
    { label: 'Flights', code: 5}]" v-validate="'required'" name="Search Type" />
        <span class="text-danger text-sm">{{ errors.first('Search Type') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { Form } from '@/core-modules/form-framework/Form.js'

export default {
  name: 'traveller-count-widget-editor',
  props: ['formData'],
  components: {
    vSelect
  },
  data () {
    return {
      form: new Form({
        searchTypeId: null
      }),      
      selectedSearchType : null
    }
  },
  computed: {   
    searchTypeOptions () {
      return this.$store.state.lookups.searchTypeOptions
    },
    additionalSettings () {
      return JSON.parse(JSON.stringify(this.formData))
    },
    validateAll () {
      // https://www.tutorialfor.com/blog-266572.htm
      // Parent component will access this to include in validation before submission
      // Note that this will return a promise
      return this.$validator.validateAll();
    }
  },
  watch: {
    formData (newVal, oldVal) {
      const { searchTypeId} = newVal
      this.form.searchTypeId = searchTypeId
      this.selectedSearchType = this.searchTypeOptions.find(x => x.code == searchTypeId) || {};
    }
  },
  methods: {
    
    setSelectedSearchType (value) {
      this.form.searchTypeId = value ? value.code : null
      this.$emit('changed', this.form.data());
    }
  }
}
</script>

<style>

</style>