import axios from "@/axios.js"
import _ from 'lodash'
import state from "../../store/state"

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    dashboards: [],
    activeDashboard: JSON.parse(localStorage.getItem('active_company_dashboard')) || null,
    widgets: []
  },
  mutations: {
    SET_DASHBOARD_LIST (state, dashboards) {
      state.dashboards = dashboards
    },
    SET_ACTIVE_DASHBOARD (state, dashboard) {
      state.activeDashboard = dashboard
    },
    REMOVE_DASHBOARD (state, dashboardId) {
      const dashboardIndex = _.findIndex(state.dashboards, (item) => item.id === dashboardId)
      state.dashboards.splice(dashboardIndex, 1)
    },
    UPDATE_DASHBOARD (state, dashboard) {
      const dashboardIndex = _.findIndex(state.dashboards, (item) => item.id === dashboard.id)
      state.dashboards[dashboardIndex] = dashboard
    },
    SET_WIDGETS_LIST (state, widgets) {
      state.widgets = widgets
    },
    UPDATE_WIDGETS_LIST (state, widgets) {
      state.widgets = widgets
    },
    REMOVE_WIDGET(state, widgetInstanceId) {
      const widgetIndex = _.findIndex(state.widgets, (item) => item.instanceId === widgetInstanceId)
      state.widgets.splice(widgetIndex, 1)
    },
    ADD_DASHBOARD (state, dashboard) {
      state.dashboards.push(dashboard)
    },
    CLEAR_WIDGET_LIST(state) {
      state.widgets = []
    }
  },
  actions: {
    fetchDashboards ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`api/companies/${payload.companyId}/dashboards`)
          .then(response => {
            commit('SET_DASHBOARD_LIST', response.data);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
    fetchWidgets ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`api/companies/${payload.companyId}/dashboards/${payload.dashboardId}`)
          .then(response => {
            commit('SET_WIDGETS_LIST', response.data);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
    updateDashboard ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.put(`api/companies/${payload.companyId}/dashboards/${payload.dashboardId}`, payload)
          .then(response => {
            const updatedDashboard = {
              id: payload.dashboardId,
              name: payload.dashboardName,
              description: payload.dashboardDescription,
              companyId: payload.companyId
            }
            commit('UPDATE_DASHBOARD', updatedDashboard)
            commit('SET_ACTIVE_DASHBOARD', updatedDashboard)
            localStorage.setItem('active_company_dashboard', JSON.stringify(updatedDashboard))
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
    removeWidget ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.delete(`api/companies/${payload.companyId}/widgets/${payload.widgetInstanceId}`)
        .then(response => {
          commit('REMOVE_WIDGET', payload.widgetInstanceId)
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },
    addDashboard ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`api/companies/${payload.companyId}/dashboards`, payload.data)
        .then(response => {
          commit('ADD_DASHBOARD', { 
            id: response.data,
            name: payload.data.name,
            description: payload.data.description,
            companyId: payload.companyId
          });
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },
    persistActiveDashboard  ({ commit }, payload) {
      commit('SET_ACTIVE_DASHBOARD', payload)
      localStorage.setItem('active_company_dashboard', JSON.stringify(payload))
    },
    setActiveDashboardToDefault ({ commit, state }) {
      if (!state.dashboards) return
      const defaultDashboard = _.find(state.dashboards, x => x.companyId === 0 )
      commit('SET_ACTIVE_DASHBOARD', defaultDashboard)
      localStorage.setItem('active_company_dashboard', JSON.stringify(defaultDashboard))
    },
    deleteDashboard ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.delete(`api/companies/${payload.companyId}/dashboards/${payload.dashboardId}`)
          .then(response => {
            commit('REMOVE_DASHBOARD', payload.dashboardId)
            resolve(response)
          }).catch(error => {
            reject(error);
          });
      })
    }
  }
}