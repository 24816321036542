<template>
  <div class="flex flex-wrap">
    <div class="w-full ml-auto mr-auto" :class="classObject">
      <h2 v-if="pageTitle" class="center-container-heading mb-6">{{ pageTitle }}</h2>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'travio-center-container',
  props: {
    pageTitle: { type: String },
    gridWidth: { type: String, default: '1/2' }
  },
  computed: {
    lgWidth () {
      return `${this.gridWidth}`
    },
    classObject () {
      return {
        'lg:w-1/2': this.gridWidth === '1/2',
        'lg:w-2/3': this.gridWidth === '2/3',
        'lg:w-full': this.gridWidth === 'full',
      }
    }
  }
}
</script>

<style scoped>
.center-container-heading {
  color: #636363
}
</style>