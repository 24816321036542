import { ref, reactive } from '@vue/composition-api'
import store from '../../store/store'
import _ from 'lodash'

const useTravioAccessControl = () => {
  const userHasPermission = (requiredPermissions) => {
    const userPermissions = store.getters['auth/getUserPermissions']
    let result = true
    if (requiredPermissions) {
      const permissions = requiredPermissions.split(',')
      result = _.find(permissions, (p) => { 
        return _.includes(userPermissions, p.trim())
      });
    }
    return result;
  }
  return { userHasPermission }
}

export default useTravioAccessControl;